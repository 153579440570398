import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ForgotId } from 'src/app/models/forgot-id';
import { MainService } from 'src/app/services/main.service';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-bap-forgot-userid',
  templateUrl: './bap-forgot-userid.component.html',
  styleUrls: ['./bap-forgot-userid.component.css']
})
export class BapForgotUseridComponent implements OnInit {
  bapUserForm!: FormGroup;
  corpCode: string;
  email: any;
  constructor(  private formBuilder: FormBuilder,
    private mainService: MainService,
    private notifyService: NotificationService,
    private router: Router,) { }
  submitted = false;
  //Forgot user ID success =1, by default its 0
  selected: any = 0;  
  emailPattern = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$";
  ngOnInit(): void {
    let urlArray =window.location.href.split(".com");
    this.corpCode= urlArray.length ==1 ?'il':urlArray [0].slice(-2);
    this.bapUserForm = this.formBuilder.group({
      email: ['', [
        Validators.required,
        Validators.pattern(this.emailPattern),

      ]],
     
    });
  }
  get userForm(): { [key: string]: AbstractControl } {
    return this.bapUserForm.controls;
  }
  onSubmit() {
    this.submitted = true;
    let body = new ForgotId(this.email.trim(), this.corpCode.toUpperCase()+'1', environment.BAP_APP_ID);
    const url = this.corpCode === 'il' ? environment.FORGOT_USERID_IL 
    : this.corpCode === 'tx' ? environment.FORGOT_USERID_TX
    : this.corpCode === 'mt' ? environment.FORGOT_USERID_MT
    : this.corpCode === 'ok' ? environment.FORGOT_USERID_OK
    : this.corpCode === 'nm' ? environment.FORGOT_USERID_NM : environment.FORGOT_USERID_IL
    this.mainService.postDataByUrlAndHeaders(body,url,{
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'X-Requested-With':'*'
      })
    }).subscribe((response: any) => {
      if(response['code'] ===200) {
        this.notifyService.showSuccess(response['message'], 'Success')
        this.selected = 1
      } else {
        this.notifyService.showError('', 'Unsuccessful')
      }
    }, error => {
      this.notifyService.showError('', 'Unsuccessful')
    })
   
  }
  onReturnBackStep0(){
    this.selected=0;
  }
  onReturnToLogin() {
    this.router.navigate(['/producer/login']);
  }
}

