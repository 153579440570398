import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-bap-email-verify',
  templateUrl: './bap-email-verify.component.html',
  styleUrls: ['./bap-email-verify.component.css']
})
export class BapEmailVerifyComponent implements OnInit {

  constructor(private router: Router,) { }
  email: any;
  ngOnInit(): void {
    this.email = "e*******@email.com";
  }
  sendCode() {
    this.router.navigate(['/producer/code-verification']);
  }
}
