import { Injectable } from '@angular/core';
import { Config, FRStep } from '@forgerock/javascript-sdk';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class DataShare {

    touAccepted:any;
    tree:any;
    public step:FRStep;

    constructor() {
      this.step=null;
    }

    setTouAccepted(touAccepted:any){
        this.touAccepted=touAccepted;
    }

    getTouAccepted(){
        return this.touAccepted;
    }

    
 
}
