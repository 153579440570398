import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-bae-header',
  templateUrl: './bae-header.component.html',
  styleUrls: ['./bae-header.component.css']
})
export class BaeHeaderComponent implements OnInit {

  imagePath:any;

  constructor() { }

  ngOnInit(): void {
    let urlArray =window.location.href.split(".com");
    this.imagePath= urlArray.length ==1 ?'il':urlArray [0].slice(-2);
  }

}
