<div class="body">
    <app-bae-header></app-bae-header>
    <main> 
        <div *ngIf="selected==0" class="fiber-grid resp-margin">
            <div class="fiber-grid__row">

                <div
                    class="baep-hero-grid baep-reg-hero-grid fiber-grid__col fiber-grid__col--xs-4 fiber-grid__col--md-5">
                    <article class="baep-hero">

                        <ul
                            class="fiber-list fiber-list--title fiber-text--size-4 fiber-list--gray fiber-list--none fiber-list--spacing-3 fiber-text--weight-bold ">
                            <li class="fiber-list__item ">
                                <a class="fiber-text--color-gray-5" active style="color:#464645">Enter Contract
                                    Information</a>
                            </li>
                            <li class="fiber-list__item">
                                <a class=" fiber-text--color-gray-3" disabled style="color:#dedede">Create User ID and
                                    Password</a>
                            </li>
                            <li class="fiber-list__item">
                                <a class="fiber-text--color-gray-3" disabled style="color:#dedede">Verify Your Email</a>

                            </li>
                        </ul>

                    </article>
                </div>

                <div
                    class="baep-form-grid fiber-grid__col fiber-grid__col--xs-4  fiber-grid__col--offset-md-1 fiber-grid__col--md-5">
                    <article class="fiber-box">

                        <form class="baep-reg-form fiber-form" aria-labelledby="Baep_Reg_Form" 
                            [formGroup]="employeerForm" (ngSubmit)="onSubmit()">

                            <fieldset class="fiber-fieldset fiber-bw-0 ">


                                <div class=" ">
                                    <span
                                        class="fiber-icon--size-1 fiber-icon--name-chevron-left-blue-primary  fiber-mr-1">

                                    </span>

                                    <span
                                        class="fiber-text--weight-normal   fiber-text--size-4 fiber-text--color-blue-primary"
                                        (click)=" onReturnToLogin()">Back</span><br>
                                    <p class="fiber-text--weight-bold fiber-text--color-gray-5 fiber-mt-3">Step 1 of 3
                                    </p>
                                </div>


                                <h1
                                    class="baep-verify-heading fiber-text--size-5 fiber-text--weight-bold fiber-text--color-gray-5 fiber-pt-3 ">
                                    Please fill out your contract information.</h1>
                                <p class="fiber-text fiber-text--size-1 fiber-text--color-text-dark ">To register for
                                    Blue Access for Employers (BAE), you must be the individual who
                                    signed the Blue Cross and Blue Shield contract. This individual is called the 'Health
                                    Group Administrator' and is considered the Security Manager.</p>
                                <p
                                    class="fiber-text fiber-text--size-1 fiber-text--color-text-dark fiber-pt-3 fiber-pb-4">
                                    Only the Health
                                    Group Administrator is allowed to complete registration.</p>
                                <div
                                    class="fiber-box fiber-s-1 fiber-br-2 fiber-mr-1 fiber-ml-1 fiber-mb-5 fiber-mt-1 fiber-p-3">
                                    <div class="form-group">
                                        <label for="accountNumber"><b>*Account Number</b></label>
                                        <input type="text" formControlName="accountNumber" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && regForm.accountNumber.errors }" (change)="getSSOID()"/>
                                        <div *ngIf="submitted && regForm.accountNumber.errors" class="invalid-feedback">
                                            <div *ngIf="regForm.accountNumber.errors.required">Account Number is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div
                                            class="form-group fiber-grid__col fiber-grid__col--xs-4 fiber-grid__col--md-7">
                                            <label for="state"><b>*Company State</b></label>
                                            <select id="stCd" name="stCd" formControlName="state" class="form-control" >
                                                <option value="AL">Alabama</option>
                                                <option value="AK">Alaska</option>
                                                <option value="AZ">Arizona</option>
                                                <option value="AR">Arkansas</option>
                                                <option value="CA">California</option>
                                                <option value="CO">Colorado</option>
                                                <option value="CT">Connecticut</option>
                                                <option value="DE">Delaware</option>
                                                <option value="DC">District of Columbia</option>
                                                <option value="FL">Florida</option>
                                                <option value="GA">Georgia</option>
                                                <option value="HI">Hawaii</option>
                                                <option value="ID">Idaho</option>
                                                <option value="IL">Illinois</option>
                                                <option value="IN">Indiana</option>
                                                <option value="IA">Iowa</option>
                                                <option value="KS">Kansas</option>
                                                <option value="KY">Kentucky</option>
                                                <option value="LA">Louisana</option>
                                                <option value="ME">Maine</option>
                                                <option value="MD">Maryland</option>
                                                <option value="MA">Massachusetts</option>
                                                <option value="MI">Michigan</option>
                                                <option value="MN">Minnesota</option>
                                                <option value="MS">Mississippi</option>
                                                <option value="MO">Missouri</option>
                                                <option value="MT">Montana</option>
                                                <option value="NE">Nebraska</option>
                                                <option value="NV">Nevada</option>
                                                <option value="NH">New Hampshire</option>
                                                <option value="NJ">New Jersey</option>
                                                <option value="NM">New Mexico</option>
                                                <option value="NY">New York</option>
                                                <option value="NC">North Carolina</option>
                                                <option value="ND">North Dakota</option>
                                                <option value="OH">Ohio</option>
                                                <option value="OK">Oklahoma</option>
                                                <option value="OR">Oregon</option>
                                                <option value="PA">Pennsylvania</option>
                                                <option value="RI">Rhode Island</option>
                                                <option value="SC">South Carolina</option>
                                                <option value="SD">South Dakota</option>
                                                <option value="TN">Tennessee</option>
                                                <option value="TX">Texas</option>
                                                <option value="UT">Utah</option>
                                                <option value="VT">Vermont</option>
                                                <option value="VA">Virginia</option>
                                                <option value="WA">Washington</option>
                                                <option value="WV">West Virginia</option>
                                                <option value="WI">Wisconsin</option>
                                                <option value="WY">Wyoming</option>
                                            </select>
                                            <div *ngIf="submitted && regForm.state.errors" class="invalid-feedback">
                                                <div *ngIf="regForm.state.errors.required">State is required</div>
                                            </div>
                                        </div>
                                        <div
                                            class="form-group fiber-grid__col fiber-grid__col--xs-4 fiber-grid__col--md-5">
                                            <label for="zipCode"><b>*Company Zip Code</b></label>
                                            <input type="text" formControlName="zipCode" class="form-control"
                                                [ngClass]="{ 'is-invalid': submitted && regForm.zipCode.errors }" />
                                            <div *ngIf="submitted && regForm.zipCode.errors" class="invalid-feedback">
                                                <div *ngIf="regForm.zipCode.errors.required">Zip Code is required</div>
                                                <div *ngIf="regForm.zipCode.errors.minlength">
                                                    Zip Code must be 5 Digit Number
                                                </div>
                                                <div *ngIf="regForm.zipCode.errors.maxlength">
                                                    Zip Code must be 5 Digit Number
                                                </div>
                                                <div *ngIf="regForm.zipCode.errors.pattern">Enter only number.</div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </fieldset>
                            <fieldset class="fiber-fieldset fiber-bw-0 ">
                                <div
                                    class="fiber-box fiber-s-1 fiber-br-2 fiber-mr-1 fiber-ml-1 fiber-mb-5 fiber-mt-1 fiber-p-3">

                                    <div class="form-group">
                                        <label for="firstName"><b>*First Name</b></label>
                                        <input type="text" formControlName="firstName" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && regForm.firstName.errors }" />
                                        <div *ngIf="submitted && regForm.firstName.errors" class="invalid-feedback">
                                            <div *ngIf="regForm.firstName.errors.required">First Name is required
                                            </div>
                                            <div *ngIf="regForm.firstName.errors.minlength">
                                                First Name minimum 2 characters
                                            </div>
                                            <div *ngIf="regForm.firstName.errors.maxlength">
                                                First Name minimum 50 characters
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="lastName"><b>*Last Name</b></label>
                                        <input type="text" formControlName="lastName" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && regForm.lastName.errors }" />
                                        <div *ngIf="submitted && regForm.lastName.errors" class="invalid-feedback">
                                            <div *ngIf="regForm.lastName.errors.required">Last Name is required
                                            </div>
                                            <div *ngIf="regForm.lastName.errors.minlength">
                                                Last Name minimum 2 characters
                                            </div>
                                            <div *ngIf="regForm.lastName.errors.maxlength">
                                                Last Name minimum 50 characters
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="email"><b>*Email Address</b></label>
                                        <input type="text" formControlName="email" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && regForm.email.errors }" />
                                        <div *ngIf="submitted && regForm.email.errors" class="invalid-feedback">
                                            <div *ngIf="regForm.email.errors.required">Email Address is required
                                            </div>
                                            <div *ngIf="regForm.email.errors.pattern">
                                                Please enter Valid Email Address
                                            </div>

                                        </div>
                                    </div>
                                    <div class="row">
                                        <div
                                            class="form-group fiber-grid__col fiber-grid__col--xs-4 fiber-grid__col--md-7">
                                            <label for="phoneCalls"><b>*Phone Number for Calls</b></label>
                                            <input type="text" formControlName="phoneCalls" maxlength="10"
                                                class="form-control"
                                                [ngClass]="{ 'is-invalid': submitted && regForm.phoneCalls.errors }" />
                                            <div *ngIf="submitted && regForm.phoneCalls.errors" class="invalid-feedback">
                                                <div *ngIf="regForm.phoneCalls.errors.required">Phone
                                                    Number is required</div>
                                                <div *ngIf="regForm.phoneCalls.errors.minlength">
                                                    Phone Number must be 10 Digit Number
                                                </div>
                                                <div *ngIf="regForm.phoneCalls.errors.maxlength">
                                                    Phone Number must be 10 Digit Number
                                                </div>
                                                <div *ngIf="regForm.phoneCalls.errors.pattern">Enter only number.</div>
                                            </div>
                                        </div>
                                        <div
                                            class="form-group fiber-grid__col fiber-grid__col--xs-4 fiber-grid__col--md-5">
                                            <label for="ext"><b>Ext.</b></label>
                                            <input type="text" formControlName="ext" class="form-control"
                                                [ngClass]="{ 'is-invalid': submitted && regForm.ext.errors }" />
                                        </div>
                                    </div>
                                    <div class="form-group ">
                                        <label for="phoneText"><b>Mobile Number</b></label>
                                        <input type="text" formControlName="phoneText" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && regForm.phoneText.errors }" />
                                        <span class="fiber-text fiber-text--size-1 fiber-text--color-text-dark  "
                                            id="ProducerZip-message" aria-labelledby="ProducerZip_Label">
                                            [Optional]
                                        </span>
                                    </div>
                                     <div class="form-group" *ngIf="showSSOID">
                                       <label for="ssoID"><b>*{{ssoIDLabel}}</b></label>
                                         <input type="text" formControlName="ssoID" class="form-control" 
                                         [ngClass]="{ 'is-invalid': submitted && regForm.ssoID.errors }"/>
                                         <div *ngIf="showSSOID && submitted && regForm.ssoID.errors" class="invalid-feedback">
                                            <div *ngIf="regForm.ssoID.errors.required">{{ssoIDLabel}} is required
                                            </div>
                                             <div *ngIf="showSSOID && regForm.ssoID.errors.minlength">
                                                {{ssoIDLabel}} minimum 2 characters
                                            </div>
                                            <div *ngIf="showSSOID && regForm.ssoID.errors.maxlength">
                                                {{ssoIDLabel}} maximum 20 characters
                                            </div> 
                                             <div *ngIf="showSSOID && regForm.ssoID.errors.pattern">Only Alphanumeric characters, Underscore and hyphen allowed.</div> 
                                        </div>
                                    </div> 
                                    <p
                                        class="fiber-text fiber-text--size-1 fiber-text--color-text-dark fiber-pt-3 fiber-pb-2 ">
                                        By saving a phone number for text messages, you are agreeing to receive
                                        recurring messages from BCBS{{corpCode.toUpperCase()}}. Message and Data rates may apply. For more
                                        information, you can view our Terms of Use and Privacy Statement.</p>
                                        
                                </div>
                            </fieldset>
                            <div class="fiber-pt-3 fiber-ta-right">
                                <button name="baep-reg-continue"
                                    class="fiber-button fiber-button--size-1 fiber-button--primary fiber-text--weight-bold fiber-mb-3 fiber-ta-right">
                                    <span class="fiber-button__text">Continue</span>
                                </button>
                            </div>

                        </form>
                    </article>
                </div>

            </div>
        </div>

        <div *ngIf="selected==1" class="fiber-grid resp-margin">
            <div class="fiber-grid__row">

                <div
                    class="baep-hero-grid baep-reg-hero-grid fiber-grid__col fiber-grid__col--xs-4 fiber-grid__col--md-5">
                    <article class="baep-hero">

                        <ul
                            class="fiber-list fiber-list--title fiber-text--size-4 fiber-list--gray fiber-list--none fiber-list--spacing-3 fiber-text--weight-bold ">
                            <li class="fiber-list__item">
                                <a class="fiber-text--color-gray-3" disabled style="color:#dedede">Enter Contract
                                    Information</a>
                            </li>
                            <li class="fiber-list__item">
                                <a class=" fiber-text--color-gray-5" active style="color:#464645">Create User ID and
                                    Password</a>
                            </li>
                            <li class="fiber-list__item">
                                <a class="fiber-text--color-gray-3" disabled style="color:#dedede">Verify Your Email</a>

                            </li>
                        </ul>

                    </article>
                </div>

                <div
                    class="baep-form-grid fiber-grid__col fiber-grid__col--xs-4  fiber-grid__col--offset-md-1 fiber-grid__col--md-5">
                    <article class="fiber-box">

                        <form class="baep-createlogin-form fiber-form" aria-labelledby="Baep_Create_Login_Form"
                            [formGroup]="employerLoginForm" (ngSubmit)="onLoginSubmit()">

                            <fieldset class="fiber-fieldset">

                                <p
                                    class="fiber-d-flex fiber-text--size-2 fiber-text--weight-bold fiber-text--color-gray-4">
                                <div class="">
                                    <span
                                        class="fiber-icon--size-1 fiber-icon--name-chevron-left-blue-primary  fiber-mr-1">

                                    </span>
                                    <span
                                        class="fiber-text--weight-normal  fiber-text--size-4 fiber-text--color-blue-primary "
                                        (click)="backtoStep0()">Back</span><br>
                                    <p class="fiber-text--weight-bold fiber-text--color-gray-5 fiber-mt-3">Step 2 of 3
                                    </p>
                                </div>



                                <h1
                                    class="baep-verify-heading fiber-text--size-5 fiber-text--weight-bold fiber-pt-3 fiber-pb-4">
                                    Create Your Secure Login.</h1>

                                <div
                                    class="fiber-box fiber-s-1 fiber-br-2 fiber-mr-1 fiber-ml-1 fiber-mb-5 fiber-mt-1 fiber-p-3">

                                    <div class="form-group">

                                        <label for="userId"><b>User ID</b></label>
                                        <div class="fiber-grid__row">

                                            <div style=" padding-right: 0px;"
                                                class="fiber-grid__col fiber-grid__col--xs-4 fiber-grid__col--sm-2 fiber-grid__col--md-12 fiber-grid__col--lg-2">
                                                <div class="fiber-input">
                                                    <input id="baep-reg-useridAcc"
                                                        class="baep-reg-userid-prefix fiber-input__input fiber-mt-2"
                                                        name="email" type="email" [value]="accNum"
                                                        disabled="true">
                                                </div>
                                            </div>
                                            <div
                                            class="fiber-grid__col fiber-grid__col--xs-4 fiber-grid__col--sm-6 fiber-grid__col--md-12 fiber-grid__col--lg-1">
                                            <div class="fiber-input" style="padding-top: 15px;margin-left: -5px;">
                                               .
                                            </div>
                                        </div>

                                            <div
                                                class="fiber-grid__col fiber-grid__col--xs-4 fiber-grid__col--sm-6 fiber-grid__col--md-12 fiber-grid__col--lg-9">
                                                <div class="fiber-input">
                                                    <input id="baep-reg-userid"style="margin-top:8px; margin-left: -38px;"
                                                        class="baep-reg-userid fiber-input__input fiber-mt-2"
                                                        type="text" minlength="5" maxlength="14"
                                                        formControlName="userId" class="form-control"
                                                     
                                                        [ngClass]="{ 'is-invalid': submitted && regForm2.userId.errors }"
                                                        aria-labelledby="baep-reg-userid" aria-required="true">
                                                        <div *ngIf="submitted && regForm2.userId.errors" style="margin-left: -38px;" class="invalid-feedback">
                                                            <div *ngIf="regForm2.userId.errors.required">User ID is required</div>

                                                            <div *ngIf="regForm2.userId.errors?.pattern">
                                                                User ID must not contain special characters
                                                            </div>
                                                            <div *ngIf="regForm2.userId.errors.minlength">
                                                                User ID must be at least 5 characters
                                                            </div>
                                                            <div *ngIf="regForm2.userId.errors.maxlength">
                                                                User ID must not exceed 14 characters
                                                            </div>
                                                            
                                                        </div>
                                                </div>
                                            </div>
                                            
                                        </div>
        
                                        
                                    </div>
                                    <div class="fiber-grid__row fiber-mb-4">

                                        <div class="fiber-grid__col fiber-grid__col--md-6">
                                            <p class="fiber-text--weight-bold fiber-text--size-1 fiber-mt-2 fiber-mb-2">

                                                User ID Must Have</p>
                                            <p class="fiber-text--size-1"><span
                                                    class="fiber-icon fiber-icon--size-1 fiber-icon--name-success fiber-mr-2"></span>5-14
                                                Characters</p>
                                        </div>

                                        <div class="fiber-grid__col fiber-grid__col--md-6">
                                            <p class="fiber-text--weight-bold fiber-text--size-1 fiber-mt-2 fiber-mb-2">
                                                User ID Can't Have
                                            </p>
                                            <p class="fiber-text--size-1"><span
                                                    class="fiber-icon fiber-icon--size-1 fiber-icon--name-alert fiber-mr-2"></span>Special
                                                Characters</p>
                                        </div>

                                    </div>


                                    <div class="form-group">
                                        <label for="password"><b>Password</b></label>
                                        <div class=" inner-addon right-addon">
                                            <div class="input-group mx-auto">
                                                <input type="password" minlength="8" maxlength="40"
                                                    formControlName="password" (ngModelChange)="passwordChange($event)"
                                                    class="form-control"
                                                    [ngClass]="{ 'is-invalid': submitted && regForm2.password.errors }"
                                                    [type]="showPassword ? 'text' : 'password'" />

                                                <div>
                                                    <i alt="show" class="far fa-eye eye-show"
                                                        (click)="showPassword =!showPassword"
                                                        [class.hide]="showPassword"><span>
                                                            {{passwordLenth}}/40</span></i>

                                                    <i alt="hide" class="far fa-eye-slash eye-hide"
                                                        (click)="showPassword =!showPassword"
                                                        [class.hide]="!showPassword"><span>
                                                            {{passwordLenth}}/40</span></i>
                                                </div>
                                                <div *ngIf="submitted && regForm2.password.errors " class="invalid-feedback">
                                                    <div *ngIf="regForm2.password.errors.required">Password is required</div>
                                                    <div *ngIf="regForm2.password.errors.minlength">
                                                        Password must be at least 8 characters
                                                    </div>
                                                    <div *ngIf="regForm2.password.errors.maxlength">
                                                        Password must not exceed 40 characters
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="fiber-grid__row fiber-mb-4">

                                        <div class="fiber-grid__col fiber-grid__col--md-6">
                                            <p class="fiber-text--weight-bold fiber-text--size-1 fiber-mt-2 fiber-mb-2">

                                                Password Must Have</p>
                                            <div *ngFor="let must of mustHaveValidation; let i=index">
                                                <p class="fiber-text--size-1"><input type="radio"
                                                        [checked]="must.selected" id="must.name{{i}}"> {{must.message}}
                                                    <br /><span style="margin-left: 12px;">{{must.terms}}</span>
                                                </p>
                                            </div>

                                        </div>

                                        <div class="fiber-grid__col fiber-grid__col--md-6">
                                            <p class="fiber-text--weight-bold fiber-text--size-1 fiber-mt-2 fiber-mb-2">
                                                Password Can't Have
                                            </p>
                                            <div *ngFor="let cant of cannotHaveValidation; let i=index">
                                                <p class="fiber-text--size-1"><input type="radio" [value]="cant.name"
                                                        [checked]="cant.selected" id="cant.name{{i}}"> {{cant.message}}
                                                    <br /><span style="margin-left: 12px;">{{cant.terms}}</span>
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </fieldset>
                            <div class="fiber-pt-3 fiber-ta-right">
                                <button class="fiber-button fiber-button--size-1 fiber-button--primary fiber-ta-right"
                                [disabled]="isSubmitButtonDisabled1 || isSubmitButtonDisabled2">
                                    <span class="fiber-button__text">Create Login</span>
                                </button>
                            </div>
                        </form>
                    </article>
                </div>

            </div>
        </div>

        <div *ngIf="selected==2" class="fiber-grid resp-margin">
            <div class="fiber-grid__row">

                <div
                    class="baep-hero-grid baep-reg-hero-grid fiber-grid__col fiber-grid__col--xs-4 fiber-grid__col--md-5">
                    <article class="baep-hero">

                        <ul
                            class="fiber-list fiber-list--title fiber-text--size-4 fiber-list--gray fiber-list--none fiber-list--spacing-3 fiber-text--weight-bold ">
                            <li class="fiber-list__item">
                                <a class="fiber-text--color-gray-3" disabled style="color:#dedede">Enter Contract
                                    Information</a>
                            </li>
                            <li class="fiber-list__item">
                                <a class=" fiber-text--color-gray-3" disabled style="color:#dedede">Create User ID and
                                    Password</a>
                            </li>
                            <li class="fiber-list__item">
                                <a class="fiber-text--color-gray-5" active style="color:#464645">Verify Your Email</a>

                            </li>
                        </ul>

                    </article>
                </div>

                <div
                    class="baep-form-grid fiber-grid__col fiber-grid__col--xs-4  fiber-grid__col--offset-md-1 fiber-grid__col--md-5">
                    <article class="fiber-box">

                        <form class="baep-verify-email-form fiber-form" aria-labelledby="Baep_Verify_Email_Form">

                            <fieldset class="fiber-fieldset">

                                <p
                                    class="fiber-d-flex fiber-text--size-2 fiber-text--weight-bold fiber-text--color-gray-4">
                                <div class="">
                                    <span
                                        class="fiber-icon--size-1 fiber-icon--name-chevron-left-blue-primary  fiber-mr-1">

                                    </span>

                                    <p class="fiber-text--weight-bold fiber-text--color-gray-5 fiber-mt-3">Step 3 of 3
                                    </p>
                                </div>



                                <h1
                                    class="baep-verify-heading fiber-text--size-5 fiber-text--weight-bold fiber-pt-3 fiber-pb-3">
                                    Verify Your email.</h1>

                                <div class="fiber-box">
                                    <b>Your User ID is</b> : <span style="color:blue;"><b>{{this.userIdshowntoUser}}</b></span><br><br>
                                  
                                    This helps us keep your account secure. Click the link in the email we sent to the email associated with your
                                        account. You can log in once your email has been verified.<br>
                                        

                                    <div
                                        class="fiber-flex fiber-flex--direction-responsive fiber-flex--wrap-nowrap fiber-flex--justify-content-space-between fiber-flex--align-items-stretch fiber-flex--gap-support fiber-flex--gap-responsive fiber-flex--basis-0 fiber-flex--divider fiber-flex--block fiber-d-flex">
                                        <button (click)="onReturnToLogin()"
                                            class="fiber-button fiber-button--size-1 fiber-button--primary fiber-button--block"
                                            data-qa-button="Return to Log In">
                                            <span class="fiber-button__text" data-qa-button-label="Return to Log In">
                                                Return to Log In
                                            </span>
                                        </button>
                                    </div>
                                </div>

                            </fieldset>


                        </form>
                    </article>
                </div>

            </div>
        </div>
    </main>

    <app-bae-footer></app-bae-footer>
</div>
