import { Component } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
@Component({
  selector: 'app-bap-verify-account',
  templateUrl: './bap-verify-account.component.html',
  styleUrls: ['./bap-verify-account.component.scss']
})
export class BapVerifyAccountComponent {

  constructor(
    public formBuilder: FormBuilder,   private router: Router,
  ) {

  }
  email: any;
  phone: any;
  ngOnInit() {
    this.email = "e*******@email.com";
    this.phone = "********001"
  }
  
  
  sendCodeform = this.formBuilder.group({
    sendCodeType: new FormControl('email', Validators.required)
  });

  get form() {
    return this.sendCodeform.get('sendCodeType');
  }

  onSubmit() {

   this.router.navigate(['/producer/email-verify']);
  }

}
