import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bap-leftcontent',
  templateUrl: './bap-leftcontent.component.html',
  styleUrls: ['./bap-leftcontent.component.css']
})
export class BapLeftcontentComponent implements OnInit {
  corpCode: any = ''
  mfaURL :any;
  state:any;
  showlink:boolean;
  
  constructor() { }

  ngOnInit(): void {
  
  this.showlink = environment.BAP_SHOW_LINK;

    let urlArray = window.location.href.split(".com");
    this.corpCode = urlArray.length == 1 ? 'il' : urlArray[0].slice(-2);
    this.state=urlArray.length == 1 ? 'il' : urlArray[0].slice(-2);
  }
    onClickFAQ() {      
    if (this.corpCode === 'il') {
      window.open( environment.BAP_FAQ_IL, '_blank')
    } else if (this.corpCode === 'tx') {
      window.open( environment.BAP_FAQ_TX, '_blank')
    } else if (this.corpCode === 'mt') {
      window.open( environment.BAP_FAQ_MT, '_blank')
    } else if (this.corpCode === 'ok') {
      window.open(  environment.BAP_FAQ_OK, '_blank')
    } else if (this.corpCode === 'nm') {
      window.open(  environment.BAP_FAQ_NM, '_blank')
    }
  }

}
