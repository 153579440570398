import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FRAuth, Config, FRStep, AttributeInputCallback, ChoiceCallback, DeviceProfileCallback, FRDevice, TextOutputCallback, FRCallback } from '@forgerock/javascript-sdk';
import { ActivatedRoute, Router } from '@angular/router';
import { HiddenValueCallback } from '@forgerock/javascript-sdk';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DataShare } from 'src/app/services/datashare';
import { environment } from 'src/environments/environment';
import { MainService } from 'src/app/services/main.service';
import { CallbackType, NameCallback, PasswordCallback } from '@forgerock/javascript-sdk';
import { NotificationService } from 'src/app/services/notification.service';
import { DomSanitizer } from '@angular/platform-browser';
import { UserSessionService } from 'src/app/services/user-session/user-session.service';

@Component({
  selector: 'app-bap-login',
  templateUrl: './bap-login.component.html',
  styleUrls: ['./bap-login.component.scss']
})
export class BapLoginComponent {
  currentStep: any;
  showPassword = false;
  loginForm!: FormGroup;
  choiceCB: any;
  codeType: any
  submitted = false;
  askDeviceSave: boolean = false;
  user = '';
  jsonData: any;
  corpCode: any = '';
  showTOU: boolean = false;
  displayDNAOption: boolean = false;
  displayEmailOption: boolean = false;
  displayPhnOption: boolean = false;
  emailIndexVal: number;
  phoneIndexVal: number;
  dnaIndexValue: number;
  email: any;
  // typingDNA: any;
  // inc: number = 67;
  typingDNAJS: any;
  // nameCallbackObject = {};
  //patternNumberCheck: number;
  //textCallbackObject = [];
  //hiddenCallbackObject = {};
  dnaDisplayMsg: string = ''
  DnaMessageVal: string = '';
  stepIs: string = '';
  phone: any;
  devicePrint: any = {};
  authnForm: FormGroup;
  singleVal: string = ''
  version: any = '';
  deviceDetails: any;
  cStep: number;
  ismulti: any;
  tree: any;
  isChecked: boolean = false;
  saveDevice: any = 0;
  mfa: boolean = false;
  otpCode: any;
  touaccepted: boolean = false;
  loadingBar: boolean = false;
  resendEmailLink: string = '';
  username: string = '';


  @Output() updatedCallback = new EventEmitter<string>();
  sendCodeform = this.formBuilder.group({
    sendCodeType: new FormControl('email', Validators.required)
  });
  amUrl: any = window.location.href.split(".com").length == 1 ? 'il' : window.location.href.split(".com")[0].slice(-2)
  // @Input() hiddencallback: HiddenValueCallback;
  previousCallback: any;
  // @Input() textcallback: TextOutputCallback;
  @BlockUI() blockUI: NgBlockUI;
  constructor(
    private formBuilder: FormBuilder,

    private router: Router,
    private dataShare: DataShare,
    private mainService: MainService,
    private notifyService: NotificationService,
    private sanitizer: DomSanitizer,
    private userSession: UserSessionService
  ) {
    this.authnForm = formBuilder.group({});
    Config.set({
      serverConfig: {
        baseUrl: this.amUrl === 'il' ? environment.AM_URL_IL
          : this.amUrl === 'tx' ? environment.AM_URL_TX
            : this.amUrl === 'mt' ? environment.AM_URL_MT
              : this.amUrl === 'ok' ? environment.AM_URL_OK
                : this.amUrl === 'nm' ? environment.AM_URL_NM : environment.AM_URL_IL,
        timeout: 10000
      },
      tree: this.tree ? this.tree : environment.DEFAULT_TREE,
      realmPath: environment.REALM_PATH,
    });


  }
  emailPattern = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$";

  ngOnInit() {
    this.resendEmailLink = environment.BAE_RESEND_EMAIL;
    this.getScrennData()
    //this.collectGeolocationInfo()
    let urlArray = window.location.href.split(".com");
    let uidArray = window.location.href.split("?uid=");
    this.corpCode = urlArray.length == 1 ? 'il' : urlArray[0].slice(-2);
    this.mainService.getBapTermsData(this.corpCode).subscribe((res: any) => {
      var doc: any = new DOMParser().parseFromString(res[0].content, "text/html");
      this.version = res[0].versionId
      this.jsonData = this.sanitizer.bypassSecurityTrustHtml(doc.firstChild.innerHTML);
    });

    this.nextStep(this.dataShare.step);
    this.loginForm = this.formBuilder.group({
      userId: ['', [Validators.required, Validators.minLength(4),
      Validators.maxLength(40)]],
      password: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(40)
      ]]
    });
    if (uidArray[1] != '' && uidArray[1] != 'undefined' && uidArray[1] != null) {
      this.loginForm.controls['userId'].setValue(uidArray[1]);
    }
    this.blockUI.stop();
  }

  // convenience getter for easy access to form fields
  get userLoginForm(): { [key: string]: AbstractControl } {
    return this.loginForm.controls;
  }

  removeSpaces() {
    this.loginForm.value.userId = this.loginForm.value.userId.trim();
    this.loginForm.value.password = this.loginForm.value.password.trim();
  }

  //u434324
  onSubmit() {
    this.removeSpaces();
    this.submitted = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {

      return;
    } else {
      this.setCallBack()

    }
    this.submitted = false;
  }

  backtoStep2() {
    this.stepIs = 'verify'
  }

  onOtpChange(code: any) {
    this.otpCode = code;
  }

  handleSelection() {
    this.codeType = this.sendCodeform.controls.sendCodeType.value
    this.choiceCallback(this.currentStep, this.codeType);
  }
  handleResend() {
    this.notifyService.showSuccess(`Code sent to ${this.codeType}`, "Success")
    this.choiceCallback(this.choiceCB, this.codeType).then(res => {

      console.log(res);
    });
  }

  async choiceCallback(step: FRStep, codeSelected: string) {
    this.choiceCB = step
    step.callbacks?.forEach((callback, index) => {


      if (callback.getType() === "ChoiceCallback") {

        if (callback.payload.output.length == 1 && callback.payload.output.values[0] === "Resend") {
          (callback as ChoiceCallback).setInputValue(0);
        }
        else if (callback.payload.input[0].name === 'IDToken1') {
          let optedType;
          if (codeSelected == 'email') {
            optedType = this.emailIndexVal;
            this.stepIs = 'otp'
          }
          else if (codeSelected == 'phone') {
            optedType = this.phoneIndexVal;


            this.stepIs = 'otp'
          }
          else {
            optedType = this.dnaIndexValue;
            this.stepIs = 'typingDna'
          }
          callback.payload.input[0].value = optedType;
        }
      }
    });
    this.nextStep(this.currentStep);
    // this.currentStep = await FRAuth.next(step);
    // return this.currentStep;
  }

  setCallBack() {

    let urlArray = window.location.href.split(".com");
    let corpCode = urlArray.length == 1 ? 'il' : urlArray[0].slice(-2);
    let i = 0
    if (this.currentStep?.type === "LoginSuccess") {

      this.redirectToProducer()
      return false
    }
    else {
      this.currentStep.callbacks.forEach(callback => {
        i++
        switch (callback.getType()) {
          case CallbackType.NameCallback: {
            (callback as NameCallback).setName(`${corpCode.toUpperCase()}1.BRK.${this.loginForm.value.userId}`);
            this.user = this.loginForm.value.userId
                 if (callback.payload.output[0].value === 'Enter one time password') {
              if (this.otpCode == null || this.otpCode == "") {
                this.otpCode = "000000000";
              }
              (callback as NameCallback).setInputValue(this.otpCode);
            }
            break;
          }



          case CallbackType.PasswordCallback: {
            if (callback.payload.output[0].value === 'One Time Password') {
              if (this.otpCode == null || this.otpCode == "") {
                this.otpCode = "000000000";
              }
              (callback as PasswordCallback).setPassword(this.otpCode);
            } else {
              (callback as PasswordCallback).setPassword(this.loginForm.value.password);
            }
            break;
          }

          case CallbackType.StringAttributeInputCallback: {
            if (callback.payload.input[0].name === "IDToken1") {
              (callback as AttributeInputCallback<any>).setInputValue(environment.BAP_APP, 0);
            } else {
              (callback as AttributeInputCallback<any>).setInputValue(this.corpCode.toUpperCase() + '1', 0);
            }

            break;

          }
          case CallbackType.ChoiceCallback: {
            if (callback.payload.output[0].value === 'The one-time verification code is incorrect. Do you want to resend?') {
              console.log('setCallback ChoiceCallback: ', callback.payload.output[0].value);
              this.notifyService.showError('The one-time verification code you entered is incorrect. Enter the correct code or click the \'resend it\' link to receive another code', 'Unsuccessful')
            } else {
              if (callback.payload.input[0].name === 'IDToken1') {
                this.cStep = this.currentStep
                this.showTOU = true
              }
              else {
                this.showTOU = false
              }
              if (callback.payload.output[0].value === 'Do you want to add this device as saved device?') {
                //this.typingdnaSaveDevice();
                (callback as ChoiceCallback).setInputValue(this.saveDevice);
                console.log('ChoiceCallback saveDevice', this.saveDevice);
                callback.payload.input[0].value = this.saveDevice;
                this.showTOU = false
              }
            }
            //this.onSubmit();
            //this.gotoNext(this.currentStep)
            break;
          }
          //added by u434324
          case CallbackType.TextOutputCallback: {

            break;
          }
          case CallbackType.HiddenValueCallback: {
            var value = (<HTMLInputElement>document.getElementById('TP')).value;
            if (value == '') {
              value = "TP";
            }
            var value2 = (<HTMLInputElement>document.getElementById('TEXT_ID')).value;


            if (callback.payload.output[1].value === 'DEVICE_TYPE') {
              (callback as HiddenValueCallback).setInputValue('0');
            } else {

              callback.payload.output[1].value === 'TEXT_ID' ? (callback as HiddenValueCallback).setInputValue(value2) : (callback as HiddenValueCallback).setInputValue(value)

            }
            break;
          }

          default: {
            console.error('Unrecognized callback type.');
            break;
          }
        }


      })
      //console.log('send response', this.currentStep);
      this.nextStep(this.currentStep)
    }

  }

  async nextStep(step: FRStep) {
    if (this.showTOU === true || this.touaccepted) {
      console.log('Show TOU is true in NextStep()');
      return;
    } else {
      this.previousCallback = step;
      this.currentStep = await FRAuth.next(step);


      this.currentStep.callbacks?.forEach((callback, index) => {
        if ((callback.getType() === 'StringAttributeInputCallback') && index == 0) {
          this.setCallBack()
        }

        if (index == 0 && callback.getType() === "PasswordCallback") {
          if (callback.payload.output[0].value === 'Password') {
            this.dataShare.step = this.currentStep;
            this.router.navigate(['/producer/update-password']);
          }

        }
        if (callback.getType() === "TextOutputCallback") {
          this.loadingBar = false;
          let callBackLen = this.currentStep?.callbacks.length;
          if (callback?.getMessageType() == 4) {
            console.log('print ', callback.getMessageType());
          }
          if (callBackLen == 7) {
            this.typingDNAJS = this.currentStep?.callbacks[3]?.payload.output[0]?.value;
          }
          else if (callBackLen == 8) {
            this.typingDNAJS = this.currentStep?.callbacks[4]?.payload.output[0]?.value;
          }
          else {
            this.typingDNAJS = '';
          }
          this.userSession.setScript(this.typingDNAJS);
          // console.log(this.typingDNAJS, 'typingDNAJS')
          let result = this.currentStep?.callbacks[0]?.payload.output[0]?.value;
          if (result === 'Please type the text below (typos allowed):') {
            this.stepIs = 'typingDna';
          } else {
            result = JSON.parse(result);
          }
          if (result?.policyRequirements[0]?.policyRequirement === "IS_NEW") {
            this.notifyService.showError("New password cannot be the same as your previous password", "Invalid Password")
          } else if (result?.policyRequirements[0]?.policyRequirement === "CHARS_NOT_ALLOWED") {
            this.notifyService.showError('Password cannot contain certain characters', "Invalid Password")
          } else if (result?.policyRequirements[0]?.policyRequirement === "SEQ_CHARS_NOT_ALLOWED") {
            this.notifyService.showError("New password cannot be the same as your previous password", "Invalid Password")
          } else if (result?.policyRequirements[0]?.policyRequirement === "CONSECUTIVE_DIGITS_NOT_ALLOWED") {
            this.notifyService.showError('Consecutive digits Not Allowed', "Invalid Password")
          } else if (result.policyRequirements[0].params.disallowedFields[0] === "givenName") {
            this.notifyService.showError("Password cannot contain a First Name or Last Name", "Invalid Password")
          } else if (result.policyRequirements[0].params.disallowedFields[0] === "sn") {
            this.notifyService.showError("Password cannot contain a First Name or Last Name", "Invalid Password")
          } else if (result.policyRequirements[0].params.disallowedFields[0] === "hcscUserNameSuffix") {
            this.notifyService.showError("Password cannot contain user id", "Invalid Password")
          } else {
            this.notifyService.showError(result?.policyRequirements[0]?.policyRequirement, "Invalid Password")
          }
          this.dataShare.step = this.previousCallback
          console.log('Data share step in nextStep()', this.dataShare.step);
          this.router.navigate(['/producer/update-password']);

        }
        if (callback.getType() === 'DeviceProfileCallback') {
          (callback as DeviceProfileCallback).setInputValue(JSON.stringify(this.deviceDetails))
          this.nextStep(this.currentStep)
        }


        if (callback.getType() === "ChoiceCallback") {
          this.loadingBar = false;
          if (callback.payload.output[0].value === 'The one-time verification code is incorrect. Do you want to resend?') {
            this.notifyService.showError('The one-time verification code you entered is incorrect. Enter the correct code or click the \'resend it\' link to receive another code', 'Unsuccessful')
          } else if (callback.payload.output[0].value === 'Do you want to add this device as saved device?') {
            this.askDeviceSave = true;
            this.stepIs = 'saveDevice';
            //this.typingdnaSaveDevice();
            // (callback as ChoiceCallback).setInputValue(this.saveDevice);
            // console.log('ChoiceCallback saveDevice', this.saveDevice);
            // callback.payload.input[0].value = this.saveDevice;
            // this.nextStep(this.currentStep)
          }


          // Added by u434324
          if (this.currentStep?.payload?.callbacks[0]?.output[0].value === 'Choose the application ID') {
            let appchoiceArr = this.currentStep?.payload?.callbacks[0]?.output[1].value;
            callback.payload.input[0].value = 1; // emp appId need to compare from property file
            this.nextStep(this.currentStep);

          }
          if (this.currentStep.payload.callbacks[0].output[0].value === 'Enter the OTP Method') {
            this.stepIs = 'verify'
            this.mfa = true;
            if (this.mfa) {
              callback.payload.input[0].value = 0;
            } else {
              callback.payload.input[0].value = 1;
            }

            if (this.currentStep?.payload?.callbacks[0]?.output[1].value.length > 1) {
              this.ismulti = true;
              let optionsArray = this.currentStep?.payload?.callbacks[0]?.output[1].value;
              optionsArray.forEach(ele => {
                let i = optionsArray.indexOf(ele);
                if (ele.includes("Email")) {
                  this.emailIndexVal = i;
                  this.email = this.currentStep?.payload?.callbacks[0]?.output[1]?.value[i]?.split(':')[1];
                  this.displayEmailOption = true;
                }
                if (ele.includes("Mobile")) {
                  this.phoneIndexVal = i;
                  this.phone = this.currentStep?.payload?.callbacks[0]?.output[1]?.value[i]?.split(':')[1];
                  this.displayPhnOption = true;

                }
                if (ele.includes("TypingDNA")) {
                  this.dnaIndexValue = i;
                  this.displayDNAOption = true;
                }
              })
            } else {
              this.ismulti = false;
              this.singleVal = this.currentStep?.payload?.callbacks[0]?.output[1].value[0];
            }
          }
          if (callback.payload.output[0].value === 'ToU is not latest') {
            this.setCallBack();
          }
        }
      })
      if (this.currentStep.type === "LoginSuccess") {
        this.setCallBack();
      } if (this.currentStep.type === "LoginFailure") {
        if (this.currentStep?.payload?.message === 'Login Failed - Email is not verified') {
          this.resendEmail();
        } else {
          this.notifyService.showError(this.currentStep?.payload?.message, "Login Fail");
          this.onReturnToLogin();
          window.location.reload();

        }
        this.currentStep = this.previousCallback;
        // this.currentStep.callbacks[1].payload.input[0].value = '';
      }

    }
  }
  resendEmail() {
    this.username = this.corpCode + 1 + '.BRK.' + this.loginForm.value.userId;
    console.log("username:" + this.username);
    this.mainService.resendBAPEmail(this.corpCode, this.username).subscribe((data: any) => {
      if (data != 'undefined' && data === "Success") {
        this.notifyService.showError('Email is not verified.<br/> We are sending you a new email to verify your email address.', "Login Fail");
      }
      else {
        this.notifyService.showError('Login Failed - Email is not verified.<br/> For assistance, call our Internet Help Desk at 1-888-706-0583', "Login Fail");
      }
    });
  }
  typingdnaSaveDevice(value) {
    if (value == "0") {
      this.saveDevice = 0;
    }
    else {
      this.saveDevice = 1;
    }
  }

  onReturnToLogin() {
    this.showTOU = false;
    this.stepIs = 'tourejected';
    this.loginForm.controls.userId.setValue('');
    this.loginForm.controls.password.setValue('');
    this.router.navigate(['/producer/login']);
  }
  onContinueNext() {
    if (this.isChecked) {
      this.saveDevice = 1;
    } else {
      this.saveDevice = 0;
    }
    this.setCallBack();
  }

  handleDevice(type) {
    this.codeType = type
  }

  redirectToProducer() {
      if (this.corpCode === 'il') {
        this.loadingBar = false;
        window.location.href = environment.BAP_FORWARD_IL
      } else if (this.corpCode === 'tx') {
        this.loadingBar = false;
        window.location.href = environment.BAP_FORWARD_TX
      } else if (this.corpCode === 'mt') {
        this.loadingBar = false;
        window.location.href = environment.BAP_FORWARD_MT
      } else if (this.corpCode === 'ok') {
        this.loadingBar = false;
        window.location.href = environment.BAP_FORWARD_OK
      } else if (this.corpCode === 'nm') {
        this.loadingBar = false;
        window.location.href = environment.BAP_FORWARD_NM
      }
    
  }

  onTermsAccept() {
    this.loadingBar = true;
    this.dataShare.setTouAccepted(new DatePipe('en-US').transform(new Date(), 'MM-dd-yyyy'));
    this.touaccepted = true;
    this.showTOU = false;
    this.stepIs = 'touaccepted';
    this.gotoNext(this.currentStep)
  }


  async gotoNext(step: FRStep) {
    this.currentStep = await FRAuth.next(step);
    if (this.currentStep.type == 'LoginSuccess') {

      this.redirectToProducer()
      return;
    }
    else {
      let touAcceptedDate = this.dataShare.getTouAccepted();
      this.currentStep.callbacks.forEach((callback) => {
        const touString = `{\"termsOfService\":{\"hcscAppID\":\"APP00000061\",\"corpCode\":\"${this.corpCode.toUpperCase()}1\",\"acceptedDate\":\"${touAcceptedDate}\",\"version\":\"${this.version}\"}}`
        switch (callback.getType() as CallbackType) {
          case CallbackType.StringAttributeInputCallback: {
            if ((callback as AttributeInputCallback<any>).getPrompt() != 'ToU') {
              if (callback.payload.input[0].name === "IDToken1") {
                (callback as AttributeInputCallback<any>).setInputValue(environment.BAP_APP, 0);
              } else {
                (callback as AttributeInputCallback<any>).setInputValue(this.corpCode.toUpperCase() + '1', 0);
              }
            } else {
              if (this.touaccepted) {
                (callback as AttributeInputCallback<any>).setInputValue(touString);
              } else {
                this.router.navigate(['/producer/login']);
              }

            }

            break;
          }

          case CallbackType.ChoiceCallback: {
            if (callback.payload.input[0].name === 'IDToken1') {
              this.cStep = this.currentStep
              this.showTOU = true;

            } else {
              this.showTOU = false

            }
            break;
          }
          default: {
            console.error('Unrecognized callback type.');
            break;
          }
        }
      })
    }
    this.gotoNext(this.currentStep)
  }
  onCreateClick() {
    this.router.navigate(['/producer/terms-conditions']);
  }
  onClickForgotPwd() {
    this.router.navigate(['/producer/forgot-password']);
  }
  onClickForgotUser() {
    this.router.navigate(['/producer/forgot-user']);
  }

  //deviceProfile

  fontDetector() {
    var detector: any = {}, baseFonts, testString, testSize, h, s, defaultWidth = {}, defaultHeight = {}, index;

    // a font will be compared against all the three default fonts.
    // and if it doesn't match all 3 then that font is not available.
    baseFonts = ['monospace', 'sans-serif', 'serif'];

    //we use m or w because these two characters take up the maximum width.
    // And we use a LLi so that the same matching fonts can get separated
    testString = "mmmmmmmmmmlli";

    //we test using 72px font size, we may use any size. I guess larger the better.
    testSize = '72px';

    h = document.getElementsByTagName("body")[0];

    // create a SPAN in the document to get the width of the text we use to test
    s = document.createElement("span");
    s.style.fontSize = testSize;
    s.innerHTML = testString;
    for (index in baseFonts) {
      //get the default width for the three base fonts
      s.style.fontFamily = baseFonts[index];
      h.appendChild(s);
      defaultWidth[baseFonts[index]] = s.offsetWidth; //width for the default font
      defaultHeight[baseFonts[index]] = s.offsetHeight; //height for the defualt font
      h.removeChild(s);
    }

    detector.detect = function (font) {
      var detected = false, index, matched;
      for (index in baseFonts) {
        s.style.fontFamily = font + ',' + baseFonts[index]; // name of the font along with the base font for fallback.
        h.appendChild(s);
        matched = (s.offsetWidth !== defaultWidth[baseFonts[index]] || s.offsetHeight !== defaultHeight[baseFonts[index]]);
        h.removeChild(s);
        detected = detected || matched;
      }
      return detected;
    };

    return detector;
  }

  collectScreenInfo = function () {
    var screenInfo: any = {};
    if (screen) {
      if (screen.width) {
        screenInfo.screenWidth = screen.width;
      }

      if (screen.height) {
        screenInfo.screenHeight = screen.height;
      }

      if (screen.pixelDepth) {
        screenInfo.screenColourDepth = screen.pixelDepth;
      }
    } else {
      console.warn("Cannot collect screen information. screen is not defined.");
    }
    return screenInfo;


  }
  collectTimezoneInfo = function () {
    var timezoneInfo: any = {}, offset = new Date().getTimezoneOffset();

    if (offset) {
      timezoneInfo.timezone = offset;
    } else {
      console.warn("Cannot collect timezone information. timezone is not defined.");
    }

    return timezoneInfo;
  }
  collectBrowserPluginsInfo = function () {

    if (navigator && navigator.plugins) {
      var pluginsInfo: any = {}, i, plugins = navigator.plugins;
      pluginsInfo.installedPlugins = "";

      for (i = 0; i < plugins.length; i++) {
        pluginsInfo.installedPlugins = pluginsInfo.installedPlugins + plugins[i].filename + ";";
      }

      return pluginsInfo;
    } else {
      console.warn("Cannot collect browser plugin information. navigator.plugins is not defined.");
      return {};
    }

  }
  // Getting geoloation takes some time and is done asynchronously, hence need a callback which is called once geolocation is retrieved.
  collectGeolocationInfo() {
    var geolocationInfo: any = {},
      successCallback = function (position) {
        geolocationInfo.longitude = position?.coords?.longitude;
        geolocationInfo.latitude = position?.coords?.latitude;
      }, errorCallback = function (error) {
        console.warn("Cannot collect geolocation information. " + error.code + ": " + error.message);
      };
    if (navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    } else {
      console.warn("Cannot collect geolocation information. navigator.geolocation is not defined.");
    }
  }
  collectBrowserFontsInfo() {
    var fontsInfo: any = {}, i, fontsList = ["cursive", "monospace", "serif", "sans-serif", "fantasy", "default", "Arial", "Arial Black",
      "Arial Narrow", "Arial Rounded MT Bold", "Bookman Old Style", "Bradley Hand ITC", "Century", "Century Gothic",
      "Comic Sans MS", "Courier", "Courier New", "Georgia", "Gentium", "Impact", "King", "Lucida Console", "Lalit",
      "Modena", "Monotype Corsiva", "Papyrus", "Tahoma", "TeX", "Times", "Times New Roman", "Trebuchet MS", "Verdana",
      "Verona"];
    fontsInfo.installedFonts = "";

    return fontsInfo;
  }


  async getScrennData() {
    this.devicePrint.screen = this.collectScreenInfo();
    this.devicePrint.timezone = this.collectTimezoneInfo();
    this.devicePrint.plugins = this.collectBrowserPluginsInfo();
    this.devicePrint.fonts = this.collectBrowserFontsInfo();

    if (navigator.userAgent) {
      this.devicePrint.userAgent = navigator.userAgent;
    }
    if (navigator.appName) {
      this.devicePrint.appName = navigator.appName;
    }
    if (navigator.appCodeName) {
      this.devicePrint.appCodeName = navigator.appCodeName;
    }
    if (navigator.appVersion) {
      this.devicePrint.appVersion = navigator.appVersion;
    }
    if (navigator.platform) {
      this.devicePrint.platform = navigator.platform;
    }
    if (navigator.product) {
      this.devicePrint.product = navigator.product;
    }
    if (navigator.productSub) {
      this.devicePrint.productSub = navigator.productSub;
    }
    if (navigator.vendor) {
      this.devicePrint.vendor = navigator.vendor;
    }
    if (navigator.vendorSub) {
      this.devicePrint.vendorSub = navigator.vendorSub;
    }
    if (navigator.language) {
      this.devicePrint.language = navigator.language;
    }
    if (navigator.languages) {
      this.devicePrint.userLanguage = navigator.languages;
    }

    const device = new FRDevice()
    //let loc = await device.getLocationCoordinates()
    let deviceMeta = await device.getDisplayMeta()
    let deviceInfo = {
      identifier: device.getIdentifier(),
      metadata: {
        hardware: {
          cpuClass: null,
          deviceMemory: 8,
          hardwareConcurrency: navigator.hardwareConcurrency,
          maxTouchPoints: navigator.maxTouchPoints,
          oscpu: null,
          display: {
            width: deviceMeta.width,
            height: deviceMeta.height,
            pixelDepth: deviceMeta.pixelDepth,
            angle: deviceMeta.angle
          }
        },
        browser: {
          userAgent: navigator.userAgent,
          appName: navigator.appName,
          appCodeName: navigator.appCodeName,
          appVersion: navigator.appVersion,
          appMinorVersion: null,
          buildID: null,
          product: navigator.product,
          productSub: navigator.productSub,
          vendor: navigator.vendor,
          vendorSub: "",
          browserLanguage: null,
          plugins: this.devicePrint.plugins.installedPlugins
        },
        platform: {
          deviceName: device.getDeviceName(),
          language: navigator.language,
          platform: this.devicePrint.platform,
          userLanguage: null,
          systemLanguage: null,
          fonts: this.devicePrint.fonts.installedFonts,
          timezone: this.devicePrint.timezone.timezone
        }
      }
    }
    this.deviceDetails = deviceInfo
  }

  updateValue(event): void {
    this.updatedCallback.emit(event.target.value);
  }
}