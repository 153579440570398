import { Component, EventEmitter, Input, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HiddenValueCallback } from '@forgerock/javascript-sdk';
import { UserSessionService } from 'src/app/services/user-session/user-session.service';

@Component({
  selector: 'app-hidden-callback',
  templateUrl: './hidden-callback.component.html',
  styleUrls: ['./hidden-callback.component.css']
})
export class HiddenCallbackComponent implements OnInit {
  @Input() callback?: HiddenValueCallback;
  @Input() user;
  @Input() authnForm;
  @Input() name?: string;

  
  @Output() updatedCallback = new EventEmitter<string>();
  id: any; 
  // callback?: HiddenValueCallback;
  hiddenForm: FormGroup;
  constructor(private userSession: UserSessionService, private fb: FormBuilder) {

  }
  ngOnInit() {
   //console.log('hidden callback', this.name)
   this.id = this.callback.payload.input[0].value;
  }

  ngAfterViewInit(){
    this.executeTypingDNAscript(this.userSession.getScript());
  }

  executeTypingDNAscript(script){
    // console.log(script)
    var runScript = new Function(script);

    return runScript();
  }
 
  updateValue(event: any): void {
    this.updatedCallback.emit(event.target.value);
  }

}
