import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-bap-header',
  templateUrl: './bap-header.component.html',
  styleUrls: ['./bap-header.component.css']
})
export class BapHeaderComponent implements OnInit {
  imagePath:any;
  constructor( ) { }

  ngOnInit(): void {
    let urlArray =window.location.href.split(".com");
    this.imagePath= urlArray.length ==1 ?'il':urlArray [0].slice(-2);
  }
}

