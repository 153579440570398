
    <article class="baep-hero fiber-box">
        <div class="fiber-box fiber-text--size-3 fiber-d-inline ">
            <h1 class="fiber-text--size-5 fiber-text--weight-bold fiber-mt-0">Blue Access for Employers provides the control you need, when you need it.</h1>
            <h2 class="fiber-text--size-3 fiber-mt-4 fiber-mb-4 fiber-mr-4">Blue Access for Employers helps you to manage your company's benefit plan and to complete your tasks, quickly and accurately. </h2>                
            <h2 class="fiber-text--size-3 fiber-mt-4 fiber-mb-4 fiber-mr-4">Register today if you are a health plan administrator and do not have access to Blue Access for Employers. </h2>

				<div *ngIf= 'showlink'>
                    <p class="fiber-ta-right">
                        <a class="fiber-link fiber-d-block fiber-ta-right fiber-link--size-1"
                            routerLink="/" (click)="onClickContactus()"
                            data-qa-link="" name="bae-contact-us">Contact Us</a>
                    </p>   
                    <p class="fiber-ta-right">
                    <a class="fiber-link fiber-d-block fiber-ta-right fiber-link--size-1" target="blank" href="{{mfaURL}}">Multifactor Authentication FAQs</a>
                    </p>  

				</div> 

        </div>
    </article>
