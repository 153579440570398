<div *ngIf="loadingBar" class="loadingRx"></div>
<div class="body">

	<app-bap-header></app-bap-header>


	<main>
		<div class="fiber-grid resp-margin">
			<div class="fiber-grid__row">
				<div class="fiber-grid__col fiber-grid__col--xs-4 fiber-grid__col--md-5">
					<app-bap-leftcontent *ngIf="!touaccepted"></app-bap-leftcontent>
				</div>

				<div
					class="baep-form-grid fiber-grid__col fiber-grid__col--xs-4  fiber-grid__col--offset-md-1 fiber-grid__col--md-5">
					<article class="fiber-box">

						<fieldset class="fiber-fieldset fiber-bw-0 ">
							<legend *ngIf="(!stepIs || stepIs=='tourejected')  && !showTOU"
								class="fiber-fieldset__legend fiber-mb-3 fiber-text--size-5 fiber-text--weight-bold">Log
								In
							</legend>
							<form class="baep-login-form fiber-form" aria-labelledby="Baep_Login_Form"
								*ngIf="(!stepIs || stepIs=='tourejected') && !showTOU" [formGroup]="loginForm"
								(ngSubmit)="onSubmit()">

								<div
									class="fiber-box fiber-s-1 fiber-br-2 fiber-mr-1 fiber-ml-1 fiber-mb-5 fiber-mt-1 fiber-p-3">
									<div class="form-group">
										<label for="userId"><b>User ID</b></label>
										<input type="text" formControlName="userId" class="form-control"
											[ngClass]="{ 'is-invalid': submitted && userLoginForm.userId.errors }" />
										<div *ngIf="submitted && userLoginForm.userId.errors" class="invalid-feedback">
											<div *ngIf="userLoginForm.userId.errors.required">User ID is required</div>
											<div *ngIf="userLoginForm.userId.errors.minlength">
												User ID must be at least 4 characters
											</div>
											<div *ngIf="userLoginForm.userId.errors.maxlength">
												User ID must not exceed 40 characters
											</div>
										</div>
									</div>


									<div class="form-group">
										<label for="password"><b>Password</b></label>
										<div class=" inner-addon right-addon">
											<div class="input-group mx-auto">
												<input type="password" formControlName="password" class="form-control"
													[ngClass]="{ 'is-invalid': submitted && userLoginForm.password.errors }"
													[type]="showPassword ? 'text' : 'password'" />

												<div>
													<i alt="show" class="far fa-eye eye-show"
														(click)="showPassword =!showPassword"
														[class.hide]="showPassword"></i>

													<i alt="hide" class="far fa-eye-slash eye-hide"
														(click)="showPassword =!showPassword"
														[class.hide]="!showPassword"></i>
												</div>
												<div *ngIf="submitted && userLoginForm.password.errors "
													class="invalid-feedback">
													<div *ngIf="userLoginForm.password.errors.required">Password is
														required</div>
													<div *ngIf="userLoginForm.password.errors.minlength">
														Password must be at least 8 characters
													</div>
													<div *ngIf="userLoginForm.password.errors.maxlength">
														Password must not exceed 40 characters
													</div>

												</div>
											</div>
										</div>
									</div>
									<div class="form-group">
										<span
											class="fiber-d-flex fiber-flex--direction-row fiber-flex--justify-content-space-between fiber-mb-2">

											<p class="fiber-ta-left">
												<a class="fiber-link fiber-d-block fiber-ta-right fiber-link--size-1"
													routerLink="/" (click)="onClickForgotUser()" data-qa-link=""
													name="baep-forgot-userId">Forgot
													User ID</a>
											</p>
											<p class="fiber-ta-right">
												<a class="fiber-link fiber-d-block fiber-ta-right fiber-link--size-1"
													routerLink="/" (click)="onClickForgotPwd()" data-qa-link=""
													name="baep-forgot-password">Forgot Password</a>
											</p>
										</span>
									</div>
								</div>
								<button name="baep-login"
									class="fiber-button fiber-button--size-1 fiber-button--primary fiber-button--block fiber-text--weight-bold fiber-mb-3">
									<span
										class="fiber-icon--size-1 fiber-icon--color-white fiber-icon--name-lock-solid  fiber-mr-1">

									</span>
									<span class="fiber-button__text">Log In</span>
								</button>
							</form>


							<div *ngIf="stepIs === 'verify'">
								<form [formGroup]="sendCodeform" class="baep-createlogin-form fiber-form"
									aria-labelledby="Baep_Create_Login_Form">
									<!-- <p>To keep your information safe, we'll send you a one-time verification code to
										enter before
										log in. </p> -->
									<p>
										To keep your information safe, choose one of the option(s)
									</p>
									<div class="fiber-box fiber-s-1 fiber-br-2 fiber-m-1 fiber-p-4">
										<p><b *ngIf="ismulti">Where should we send your code?</b> <b
												*ngIf="!ismulti">Your code will be sent to: </b></p>
										<span *ngIf="!ismulti">{{singleVal}}</span>
										<div fxLayout="column" *ngIf="ismulti">
											<div *ngIf="displayEmailOption">

												<input type="radio" value="email" id="email"
													(change)="handleDevice('email')" formControlName="sendCodeType">
												<label for="email"><span style="margin-left: 20px;">Send verification
														code in an email to</span><br />
													<span style="margin-left: 40px;">{{email}}</span></label>

											</div>
											<br>
											<div *ngIf="displayPhnOption">
												<input type="radio" value="phone" id="phone"
													(change)="handleDevice('phone')" formControlName="sendCodeType">
												<label for="phone"><span style="margin-left: 20px;">
													Send verification code in an SMS Text to
													</span><br />
													<span style="margin-left: 40px;">{{phone}}</span></label>
											</div>
											<br>
											<div *ngIf='displayDNAOption'>
												<input type="radio" value="typingDNA" id="typingDNA"
													(change)="handleDevice('typingDNA')" formControlName="sendCodeType">
												<label for="phone"><span style="margin-left: 20px;">Prompt me for a
														personalized keyboard typing pattern
													</span><br />
												</label>
											</div>



										</div>

									</div>
									<div fxLayout="row" fxLayoutAlign="end" class="fiber-pt-3">
										<button class="btn btn-primary col-4" style="border-radius: 50px;"
											(click)="handleSelection()">
											Send </button>
									</div>
								</form>
							</div>
							<!-- added for typingdna -->

							<div *ngIf="this.stepIs == 'typingDna'">

								<div class="fiber-box fiber-s-1 fiber-br-2 fiber-m-1 fiber-p-4">
									<div class="">
										<form [formGroup]="authnForm" @fadeSlideInOut (ngSubmit)="setCallBack()">
											<ng-container
												*ngFor="let callback of currentStep ? currentStep.callbacks : null; let i = index">

												<span [ngSwitch]="callback.getType()">
													<ng-container *ngSwitchCase="'TextOutputCallback'">


														<app-text-callback [callback]="callback">

														</app-text-callback>
													</ng-container>
													<!-- [authnForm]="hiddenCallbackObject.form" -->
													<ng-container *ngSwitchCase="'HiddenValueCallback'">
														<app-hidden-callback [user]="user" [callback]="$any(callback)"
															[name]="callback?.payload?.input?.[0]?.name"
															(updatedCallback)="$any(callback).setInputValue($event)">

														</app-hidden-callback>
													</ng-container>
												</span>
											</ng-container>
											<input class="form-control" id="idToken3" type="type"
												placeholder="Please enter the above text">

											<button type="submit" class="btn btn-primary mt-2"
												id="loginButton_0">Submit</button>
										</form>
									</div>

								</div>
							</div>
							<!-- ----- code for typing dna save device-------- -->
							<div *ngIf="askDeviceSave && !showTOU">
								<div class="fiber-box fiber-s-1 fiber-br-2 fiber-m-1 fiber-p-4">
									<p><b>Do you want to remember this device for 30 days?</b></p>
									<input  type="radio" value="1" id="yes" name="device"
										(change)="typingdnaSaveDevice(1)"><label class="ml-1" for="yes">Yes</label>
									<br>
									<input  type="radio" value="0" id="no" name="device"
										(change)="typingdnaSaveDevice(0)"><label class="ml-1" for="no">No</label>
									<div>
										<button type="button" class="btn btn-primary mt-2"
											(click)="setCallBack()">Submit</button>
									</div>
								</div>
							</div>
							



							<div *ngIf="stepIs === 'otp' && !showTOU"
								class="baep-form-grid fiber-grid__col fiber-grid__col--xs-4  fiber-grid__col--offset-md-1 fiber-grid__col--md-12">
								<article class="fiber-box">

									<form class="baep-verify-email-form fiber-form"
										aria-labelledby="Baep_Verify_Email_Form">

										<fieldset class="fiber-fieldset">

											<p
												class="fiber-d-flex fiber-text--size-2 fiber-text--weight-bold fiber-text--color-gray-4">


											<h1
												class="baep-verify-heading fiber-text--size-5 fiber-text--weight-bold fiber-pt-3 fiber-pb-3">
												Verify your account.</h1>

											<div class="fiber-box fiber-s-1 fiber-br-2 fiber-m-1 fiber-p-4">
												<p>Enter your one-time verification code </p>
												<p>Didn't get a code? Click here to <a
														href="javascript:void(0)" (click)="handleResend()"> resend
														it.</a></p>

												<div fxLayout="column">
													<div>
														<ng-otp-input (onInputChange)="onOtpChange($event)" [config]="{length:8,isPasswordInput:true,
												inputStyles:{width:'30px',height:'40px'}}"></ng-otp-input>
													</div>
<!-- 
													<div fxLayout="row">

														<label class="fiber-checkbox">
															<input type="checkbox"
																style="width: 20px;height:20px; margin-top: 10px"
																[(ngModel)]="isChecked" [checked]="isChecked">
															<span class="fiber-checkbox__label">
																Remember this device for 30 days.
																<span class="icon-tooltip-container">
																	<span
																		class="fiber-icon-name-information fiber-icon--size-1 fiber-m-0 fiber-icon--color-gray-5"></span>
																	<div class="fiber-tooltip fiber-tooltip--element">
																		<span
																			class="fiber-text--size-2 fiber-text--color-white fiber-text--weight-bold"
																			role="heading">Remember This Device</span>
																		<p
																			class=" fiber-text--size-2 fiber-text--color-white fiber-pt-4 fiber-pb-2">
																			This will allow you to skip the verification
																			steps on
																			this device
																			for 30 days after each login. You should
																			only save
																			devices you
																			trust.</p>
																	</div>
																</span>
															</span>
														</label>
													</div> -->


												</div>

											</div>
											<div fxLayout="row" fxLayoutAlign="end" class="fiber-pt-3">
												<button class="btn btn-primary col-4" (click)="onContinueNext()"
													style="border-radius: 50px;">
													Submit </button>
											</div>
										</fieldset>


									</form>
								</article>
							</div>
							<div *ngIf="stepIs === 'touaccepted' &&  !showTOU"
								class="baep-form-grid fiber-grid__col fiber-grid__col--xs-4  fiber-grid__col--offset-md-1 fiber-grid__col--md-12">

							</div>


							<div *ngIf="showTOU">
								<h1
									class="baep-verify-heading fiber-text--size-5 fiber-text--weight-bold fiber-text--color-gray-5 fiber-pt-3 fiber-pb-4">
									Terms of Use.</h1>

								<ng-container>
									<div>


										<br />
										<div class="myTemplate" style="height:400px;padding-left: 80px;overflow: auto">

											<div>
												<p [innerHTML]="jsonData"> </p>
											</div>
										</div>
									</div>

								</ng-container>

								<div class="fiber-pt-3 fiber-ta-right">
									<button name="baep-reg-continue" (click)=" onReturnToLogin()"
										class="fiber-button fiber-button--size-1 fiber-button--inverse  fiber-text--weight-bold fiber-mr-4 fiber-bc-blue-primary fiber-c-blue-primary fiber-bg-gray-0 fiber-mb-3 fiber-ta-right">
										<span class="fiber-button__text">Reject</span>
									</button>
									<button name="baep-reg-continue" (click)="onTermsAccept()"
										class="fiber-button fiber-button--size-1 fiber-button--primary fiber-text--weight-bold fiber-mb-3 fiber-ta-right">
										<span class="fiber-button__text">I Accept</span>
									</button>
								</div>
							</div>

							<div style="margin-top: 20px;" *ngIf="!showTOU">
								<button name="baep-register" (click)="onCreateClick()" *ngIf="!stepIs"
									class="fiber-button fiber-button--size-1 fiber-button--inverse fiber-button--block fiber-bc-blue-primary fiber-c-blue-primary fiber-bg-gray-0 fiber-bw-2">
									<span class="fiber-button__text">New User? Register Here</span>
								</button>
							</div>
						</fieldset>



					</article>
				</div>

			</div>

		</div>
	</main>
	<app-bap-footer></app-bap-footer>

</div>
