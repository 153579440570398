import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bae-leftcontent',
  templateUrl: './bae-leftcontent.component.html',
  styleUrls: ['./bae-leftcontent.component.css']
})
export class BaeLeftcontentComponent implements OnInit {
  corpCode: any = ''
  mfaURL :any;
  state:any;
  showlink:boolean;
  constructor() { }

  ngOnInit(): void {
	
	this.showlink = environment.BAE_SHOW_LINK;

    let urlArray = window.location.href.split(".com");
    this.corpCode = urlArray.length == 1 ? 'il' : urlArray[0].slice(-2);
    this.state=urlArray.length == 1 ? 'il' : urlArray[0].slice(-2);
    if(this.state === 'il'){
      this.mfaURL='assets/BCBS_BAE_MFA_FAQ_IL.pdf';
    }else if(this.state === 'nm'){
      this.mfaURL='assets/BCBS_BAE_MFA_FAQ_NM.pdf';
    }else if(this.state === 'mt'){
      this.mfaURL='assets/BCBS_BAE_MFA_FAQ_MT.pdf';
    }else if(this.state === 'tx'){
      this.mfaURL='assets/BCBS_BAE_MFA_FAQ_TX.pdf';
    }else if(this.state === 'ok'){
      this.mfaURL='assets/BCBS_BAE_MFA_FAQ_OK.pdf';
    }
  }

  onClickContactus() {
    //window.open(environment.BAE_CONTACT_US_IL );
    if (this.corpCode === 'il') {
      window.open( environment.BAE_CONTACT_US_IL, '_blank')
    } else if (this.corpCode === 'tx') {
      window.open( environment.BAE_CONTACT_US_TX, '_blank')
    } else if (this.corpCode === 'mt') {
      window.open( environment.BAE_CONTACT_US_MT, '_blank')
    } else if (this.corpCode === 'ok') {
      window.open(  environment.BAE_CONTACT_US_OK, '_blank')
    } else if (this.corpCode === 'nm') {
      window.open(  environment.BAE_CONTACT_US_NM, '_blank')
    }
  }

}
