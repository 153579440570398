import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TextOutputCallback } from '@forgerock/javascript-sdk';
import { fadeSlideInOut } from 'src/app/services/animations/animations';
import { UserSessionService } from 'src/app/services/user-session/user-session.service';

@Component({
  selector: 'app-text-callback',
  templateUrl: './text-callback.component.html',
  styleUrls: ['./text-callback.component.css'],
  animations: [
    fadeSlideInOut
  ]
})
export class TextCallbackComponent implements OnInit {
  @Input() callback;
  @Input() header: string;
  message: string;
  prompt: string; 
  textForm: FormGroup;
  error: string;
  errors = false;
  constructor(private userSession: UserSessionService, private fb: FormBuilder) {

  }

   ngOnInit() {

   this.message = this.callback.payload.output[0].value;
  //  if(this.message.includes('pattern(s) enrolled')){
  //  switch(this.message){
  //    case 'Not enough typing patterns to perform authentication. 3 pattern(s) enrolled.':{
  //       this.message = '3 typing patterns enrolled. Please type the above phrase one more time to verify your account.'
  //    }
  //    case 'Not enough typing patterns to perform authentication. 2 pattern(s) enrolled.':{
  //     this.message = '2 typing patterns enrolled. Please type the above phrase two more times continue.'

  //   }
  //   case 'Not enough typing patterns to perform authentication. 1 pattern(s) enrolled.':{
  //     this.message = '1 typing pattern enrolled. Please type the above phrase three more times continue.'
  //   }
  //   default:{
  //     this.message = this.message;
  //   }
  // }
  //  }
  //  if(this.message.includes('/**')){
  //    this.executeTypingDNAScript(this.message);
  //  
  
}
ngAfterViewInit(){
  this.executeTypingDNAScript(this.userSession.getScript());
}
  formatHeader(header){
    return header.replace(":", '')
  }
  executeTypingDNAScript(script) {
    var runScript = new Function(script);

   return runScript();

    
  }

 
}
