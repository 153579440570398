export class ValidateAccount{
    constructor(
        public accountNumber:any,
        public stateCode:any,
        public zipCode:any,
        public firstName:any,
        public lastName:any,
        public email:any,
        public corporateCode:any
    ){
    }
}

export class ValidateSSOAccount{
    constructor(
        public accountNumber:any,
        public stateCode:any,
        public zipCode:any,
        public firstName:any,
        public lastName:any,
        public email:any,
        public corporateCode:any,
        public hcscSSOUid:any,
        public hcscSSOClientid:any
    ){
    }
}