<div class="body">
    <app-bae-header></app-bae-header>
    <main>
        <div *ngIf="selected==0" class="fiber-grid resp-margin">
            <div class="fiber-grid__row">

                <div
                    class="baep-hero-grid baep-reg-hero-grid fiber-grid__col fiber-grid__col--xs-4 fiber-grid__col--md-5">
                    <article class="baep-hero">

                        <ul
                            class="fiber-list fiber-list--title fiber-text--size-4 fiber-list--gray fiber-list--none fiber-list--spacing-3 fiber-text--weight-bold ">
                            <li class="fiber-list__item">
                                <a class="fiber-text--color-gray-2" active style="color:#464645" (click)="onReturnToLogin()">Home</a>
                            </li>
                            <li class="fiber-list__item">
                                <a class="fiber-text--color-gray-5" active style="color:#464645">Enter Your Username</a>
                            </li>
                            <li class="fiber-list__item">
                                <a class=" fiber-text--color-gray-3" disabled style="color:#dedede">Get Verification Code</a>
                            </li>
                            <li class="fiber-list__item">
                                <a class="fiber-text--color-gray-3" disabled style="color:#dedede">Enter Verification Code</a>

                            </li>
                            <li class="fiber-list__item">
                                <a class="fiber-text--color-gray-3" disabled style="color:#dedede">Create New Password</a>

                            </li>
                        </ul>

                    </article>
                </div>

                <div
                    class="baep-form-grid fiber-grid__col fiber-grid__col--xs-4  fiber-grid__col--offset-md-1 fiber-grid__col--md-5">
                    <article class="fiber-box">

                        <form class="baep-reg-form fiber-form" aria-labelledby="Baep_Reg_Form"
                        [formGroup]="baeUserIDForm" (ngSubmit)="onSubmit()" >

                            <fieldset class="fiber-fieldset fiber-bw-0 ">

                                <p
                                    class="fiber-d-flex fiber-text--size-2 fiber-text--weight-bold fiber-text--color-gray-4">

                                    <div class=" ">
                                    <p class="fiber-text--weight-bold fiber-text--color-gray-5 fiber-mt-3">Step 1 of 4
                                    </p>
                                </div>

                                <h1
                                    class="baep-verify-heading fiber-text--size-5 fiber-text--weight-bold fiber-text--color-gray-5 fiber-pt-3 fiber-pb-4">
                                    Forgot Password.</h1>

                                <div
                                    class="fiber-box fiber-s-1 fiber-br-2 fiber-mr-1 fiber-ml-1 fiber-mb-5 fiber-mt-1 fiber-p-3">
                                    <div class="form-group">
										<label for="userId"><b>User ID</b></label>
										<input type="text" formControlName="userId" class="form-control"
											[ngClass]="{ 'is-invalid': submitted && userIDForm.userId.errors }" />
										<div *ngIf="submitted && userIDForm.userId.errors" class="invalid-feedback">
											<div *ngIf="userIDForm.userId.errors.required">User ID is required</div>
											<div *ngIf="userIDForm.userId.errors.minlength">
												User ID must be at least 4 characters
											</div>
											<div *ngIf="userIDForm.userId.errors.maxlength">
												User ID must not exceed 40 characters
											</div>
										</div>
									</div>

                                </div>

                            </fieldset>
                            <!-- if they click on login, send them to bae login, screen two, bae login -->
                            <div class="fiber-pt-3 fiber-ta-right">
                                <button name="baep-reg-continue" (click)="findAccount()"
                                    class="fiber-button fiber-button--size-1 fiber-button--primary fiber-text--weight-bold fiber-mb-3 fiber-ta-right">
                                    <span class="fiber-button__text">Find Account</span>
                                </button>
                            </div>

                        </form>
                    </article>
                </div>

            </div>
        </div>

        <div *ngIf="selected==1" class="fiber-grid resp-margin">
            <div class="fiber-grid__row">

                <div
                    class="baep-hero-grid baep-reg-hero-grid fiber-grid__col fiber-grid__col--xs-4 fiber-grid__col--md-5">
                    <article class="baep-hero">

                        <ul
                            class="fiber-list fiber-list--title fiber-text--size-4 fiber-list--gray fiber-list--none fiber-list--spacing-3 fiber-text--weight-bold ">
                            <li class="fiber-list__item">
                                <a class="fiber-text--color-gray-2" active style="color:#464645"  (click)="onReturnToLogin()">Home</a>
                            </li>
                            <li class="fiber-list__item">
                                <a class="fiber-text--color-gray-3" disabled style="color:#dedede">Enter Your Username</a>
                            </li>
                            <li class="fiber-list__item">
                                <a class=" fiber-text--color-gray-5" active style="color:#464645">Get Verification Code</a>
                            </li>
                            <li class="fiber-list__item">
                                <a class="fiber-text--color-gray-3" disabled style="color:#dedede">Enter Verification Code</a>

                            </li>
                            <li class="fiber-list__item">
                                <a class="fiber-text--color-gray-3" disabled style="color:#dedede">Create New Password</a>

                            </li>
                        </ul>

                    </article>
                </div>

                <div
                    class="baep-form-grid fiber-grid__col fiber-grid__col--xs-4  fiber-grid__col--offset-md-1 fiber-grid__col--md-5">
                    <article class="fiber-box">

                        <form [formGroup]="sendCodeform" (ngSubmit)="onSubmitSendCode()"
                            class="baep-createlogin-form fiber-form" aria-labelledby="Baep_Create_Login_Form">


                            <fieldset class="fiber-fieldset">

                                <p
                                    class="fiber-d-flex fiber-text--size-2 fiber-text--weight-bold fiber-text--color-gray-4">
                                    <div class="">
                                    <p class="fiber-text--weight-bold fiber-text--color-gray-5 fiber-mt-3">Step 2 of 4
                                    </p>
                                </div>


                                <h1
                                    class="baep-verify-heading fiber-text--size-5 fiber-text--weight-bold fiber-pt-3 fiber-pb-4">
                                    Forgot Password</h1>

                                <div>
                                    <p>To keep your information safe, we'll send you a one-time verification code to
                                        enter before
                                        log in. </p>
                                    <div class="fiber-box fiber-s-1 fiber-br-2 fiber-m-1 fiber-p-4">
                                        <p><b *ngIf="ismulti">Where should we send your code?</b> <b *ngIf="!ismulti">Your code will be sent to: </b></p>
                                            <span *ngIf="!ismulti">{{singleVal}}</span>
                                        <div fxLayout="column" *ngIf="ismulti">
                                            <div>

                                                <input type="radio" value="email" id="email" (change)="handleDevice('email')" formControlName="sendCodeType">
                                                <label for="email" ><span style="margin-left: 20px;">Send me an email</span><br />
                                                <span style="margin-left: 40px;">{{email}}</span></label>

                                            </div>
                                             <div>
                                                <input type="radio" value="phone" id="phone"(change)="handleDevice('phone')" formControlName="sendCodeType">
                                                <label for="phone" ><span style="margin-left: 20px;">Send me a text
                                                    message</span><br/>
                                                    <span style="margin-left: 40px;">{{phone}}</span></label>
                                            </div>


                                        </div>

                                    </div>
                                    <div fxLayout="row" fxLayoutAlign="end" class="fiber-pt-3">
                                        <button class="btn btn-primary col-4" style="border-radius: 50px;" (click)="handleSelection()">
                                            Send Code </button>
                                    </div>
                                </div>

                            </fieldset>

                        </form>
                    </article>
                </div>

            </div>
        </div>

        <div *ngIf="selected==2" class="fiber-grid resp-margin">
            <div class="fiber-grid__row">

                <div
                    class="baep-hero-grid baep-reg-hero-grid fiber-grid__col fiber-grid__col--xs-4 fiber-grid__col--md-5">
                    <article class="baep-hero">

                        <ul
                            class="fiber-list fiber-list--title fiber-text--size-4 fiber-list--gray fiber-list--none fiber-list--spacing-3 fiber-text--weight-bold ">
                            <li class="fiber-list__item">
                                <a class="fiber-text--color-gray-2" active style="color:#464645"  (click)="onReturnToLogin()">Home</a>
                            </li>
                            <li class="fiber-list__item">
                                <a class="fiber-text--color-gray-3" disabled style="color:#dedede">Enter Your Username</a>
                            </li>
                            <li class="fiber-list__item">
                                <a class=" fiber-text--color-gray-3" disabled style="color:#dedede">Get Verification Code</a>
                            </li>
                            <li class="fiber-list__item">
                                <a class="fiber-text--color-gray-5" active style="color:#464645">Enter Verification Code</a>

                            </li>
                            <li class="fiber-list__item">
                                <a class="fiber-text--color-gray-3" disabled style="color:#dedede">Create New Password</a>

                            </li>

                        </ul>

                    </article>
                </div>

                <div
                    class="baep-form-grid fiber-grid__col fiber-grid__col--xs-4  fiber-grid__col--offset-md-1 fiber-grid__col--md-5">
                    <article class="fiber-box">

                        <form class="baep-verify-email-form fiber-form" aria-labelledby="Baep_Verify_Email_Form">

                            <fieldset class="fiber-fieldset">

                                <p
                                    class="fiber-d-flex fiber-text--size-2 fiber-text--weight-bold fiber-text--color-gray-4">
                                    <div class="">
                                    <p class="fiber-text--weight-bold fiber-text--color-gray-5 fiber-mt-3">Step 3 of 4
                                    </p>
                                </div>

                                <h1
                                    class="baep-verify-heading fiber-text--size-5 fiber-text--weight-bold fiber-pt-3 fiber-pb-3">
                                    Forgot Password</h1>

                                <div class="fiber-box fiber-s-1 fiber-br-2 fiber-m-1 fiber-p-4">
                                    <p>Enter your one-time verification code </p>
                                    <p>Didn't get a code? Click here to <a href="javascript:void(0)" (click)="handleResend()"> resend it.</a></p>

                                    <div fxLayout="column">
                                        <div>
                                            <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="{length:8,isPasswordInput:true,
                                        inputStyles:{width:'30px',height:'40px'}}"></ng-otp-input>
                                        </div>
                                        


                                    </div>

                                </div>
                                <div fxLayout="row" fxLayoutAlign="end" class="fiber-pt-3">
                                    <button class="btn btn-primary col-4" (click)="onContinueNext()"
                                        style="border-radius: 50px;">
                                       Submit </button>
                                </div>
                            </fieldset>


                        </form>
                    </article>
                </div>

            </div>
        </div>
        <div *ngIf="selected==3" class="fiber-grid resp-margin">
            <div class="fiber-grid__row">

                <div
                    class="baep-hero-grid baep-reg-hero-grid fiber-grid__col fiber-grid__col--xs-4 fiber-grid__col--md-5">
                    <article class="baep-hero">

                        <ul
                            class="fiber-list fiber-list--title fiber-text--size-4 fiber-list--gray fiber-list--none fiber-list--spacing-3 fiber-text--weight-bold ">
                            <li class="fiber-list__item">
                                <a class="fiber-text--color-gray-2" active style="color:#464645"  (click)="onReturnToLogin()">Home</a>
                            </li>
                            <li class="fiber-list__item">
                                <a class="fiber-text--color-gray-3" disabled style="color:#dedede">Enter Your Username</a>
                            </li>
                            <li class="fiber-list__item">
                                <a class=" fiber-text--color-gray-3" disabled style="color:#dedede">Get Verification Code</a>
                            </li>
                            <li class="fiber-list__item">
                                <a class="fiber-text--color-gray-3" disabled style="color:#dedede">Enter Verification Code</a>

                            </li>

                            <li class="fiber-list__item">
                                <a class="fiber-text--color-gray-5" active style="color:#464645">Create New Password</a>

                            </li>
                        </ul>

                    </article>
                </div>

                <div
                    class="baep-form-grid fiber-grid__col fiber-grid__col--xs-4  fiber-grid__col--offset-md-1 fiber-grid__col--md-5">
                    <article class="fiber-box">

                        <form class="baep-verify-email-form fiber-form" aria-labelledby="Baep_Verify_Email_Form"
                        [formGroup]="baePasswordForm" (ngSubmit)="onLoginSubmit()">

                            <fieldset class="fiber-fieldset">

                                <p
                                    class="fiber-d-flex fiber-text--size-2 fiber-text--weight-bold fiber-text--color-gray-4">
                                    <div class="">
                                    <p class="fiber-text--weight-bold fiber-text--color-gray-5 fiber-mt-3">Step 3 of 4
                                    </p>
                                </div>


                                <h1
                                    class="baep-verify-heading fiber-text--size-5 fiber-text--weight-bold fiber-pt-3 fiber-pb-3">
                                    Create new password</h1>
                                <h2 class="baep-verify-heading fiber-text--size-3  fiber-pt-3 fiber-pb-3">
                                    Enter a new password for your account.</h2>
                                <div
                                    class="fiber-box fiber-s-1 fiber-br-2 fiber-mr-1 fiber-ml-1 fiber-mb-5 fiber-mt-1 fiber-p-3">


                                    <div class="form-group">
                                        <label for="password"><b>Password</b></label>
                                        <div class=" inner-addon right-addon">
                                            <div class="input-group mx-auto">
                                                <input type="password" minlength="8" maxlength="40"
                                                    formControlName="password" (ngModelChange)="passwordChange($event)"
                                                    class="form-control"
                                                    [ngClass]="{ 'is-invalid': submitted && passwordForm.password.errors }"
                                                    [type]="showPassword ? 'text' : 'password'" />

                                                <div>
                                                    <i alt="show" class="far fa-eye eye-show"
                                                        (click)="showPassword =!showPassword"
                                                        [class.hide]="showPassword"><span>
                                                            {{passwordLenth}}/40</span></i>

                                                    <i alt="hide" class="far fa-eye-slash eye-hide"
                                                        (click)="showPassword =!showPassword"
                                                        [class.hide]="!showPassword"><span>
                                                            {{passwordLenth}}/40</span></i>
                                                </div>
                                                <div *ngIf="submitted && passwordForm.password.errors " class="invalid-feedback">
                                                    <div *ngIf="passwordForm.password.errors.required">Password is required</div>
                                                    <div *ngIf="passwordForm.password.errors.minlength">
                                                        Password must be at least 8 characters
                                                    </div>
                                                    <div *ngIf="passwordForm.password.errors.maxlength">
                                                        Password must not exceed 40 characters
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <p class="fiber-text--size-2">
                                        Your account's security is very important to us.</p>
                                        <div class="fiber-grid__row fiber-mb-4">

                                            <div class="fiber-grid__col fiber-grid__col--md-6">
                                                <p class="fiber-text--weight-bold fiber-text--size-1 fiber-mt-2 fiber-mb-2">

                                                    Password Must Have</p>
                                                <div *ngFor="let must of mustHaveValidation; let i=index">
                                                    <p class="fiber-text--size-1"><input type="radio" [checked]="must.selected"
                                                            id="must.name{{i}}"> {{must.message}}
                                                        <br /><span style="margin-left: 12px;">{{must.terms}}</span>
                                                    </p>
                                                </div>

                                            </div>

                                            <div class="fiber-grid__col fiber-grid__col--md-6">
                                                <p class="fiber-text--weight-bold fiber-text--size-1 fiber-mt-2 fiber-mb-2">
                                                    Password Can't Have
                                                </p>
                                                <div *ngFor="let cant of cannotHaveValidation; let i=index">
                                                    <p class="fiber-text--size-1"><input type="radio" [value]="cant.name"  [checked]="cant.selected"
                                                            id="cant.name{{i}}"> {{cant.message}}
                                                        <br /><span style="margin-left: 12px;">{{cant.terms}}</span></p>
                                                        
                                                    </div>
                                                    <p>Your first or last name, or username</p><br>
													<p>Last 24 passwords cannot be used</p>
                                            </div>

                                        </div>


                                </div>
                            </fieldset>
                            <div class="fiber-pt-3 fiber-ta-right">
                                <button class="fiber-button fiber-button--size-1 fiber-button--primary fiber-ta-right"
                                [disabled]="isSubmitButtonDisabled1 || isSubmitButtonDisabled2"
                                (click)="onSubmitPassword()">
                                    <span class="fiber-button__text">Submit</span>
                                </button>
                            </div>

                        </form>
                    </article>
                </div>

            </div>
        </div>
    </main>

    <app-bae-footer></app-bae-footer>
</div>
