import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Config, FRAuth, FRStep } from '@forgerock/javascript-sdk';
import * as moment from 'moment';
import { DataShare } from 'src/app/services/datashare';
import { MainService } from 'src/app/services/main.service';
import {  CallbackType, NameCallback, PasswordCallback, ChoiceCallback, AttributeInputCallback } from '@forgerock/javascript-sdk';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-bae-forgot-password',
  templateUrl: './bae-forgot-password.component.html',
  styleUrls: ['./bae-forgot-password.component.css']
})
export class BaeForgotPasswordComponent implements OnInit {
  showPassword = false;
  user: string = '';
  baeUserIDForm!: FormGroup;
  baePasswordForm!: FormGroup;
  tree: any;
  isSubmitButtonDisabled1 = true;
  isSubmitButtonDisabled2 = true;
  currentStep: any;
  loading = false;
   backupStep: FRStep;
  submitted = false;
  passwordLenth = 0;
  //choice callback
  choiceCB =null;
  singleVal: string = ''
  selected: any = 0;
  passwordLength = 0;
  codeType: string = 'email';
  amUrl: any = window.location.href.split(".com").length == 1 ? 'il' : window.location.href.split(".com")[0].slice(-2)
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private dataShare: DataShare,
    private notifyService: NotificationService,
  ) {
    Config.set({
      serverConfig: { baseUrl: this.amUrl === 'il' ?  environment.AM_URL_IL
       : this.amUrl === 'tx' ? environment.AM_URL_TX
       : this.amUrl === 'mt' ? environment.AM_URL_MT
       : this.amUrl === 'ok' ? environment.AM_URL_OK
       : this.amUrl === 'nm' ? environment.AM_URL_NM : environment.AM_URL_IL,
       timeout: 10000 },
      tree: this.tree ? this.tree : environment.FORGOT_PASSWORD_TREE,
      realmPath: environment.REALM_PATH,
    });
  }

  emailPattern = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$";
  email: any;
  stepIs: string = '';
  phone: any;
  corpCode: any;
  ismulti: boolean = false;
  userDetails: any;
  otpCode: any;
  isChecked: boolean = false;
  errorpwd: boolean = false;
  mustHaveValidation = [
    { name: 'minAndMax', message: '8-40 characters', selected: false },
    {
      name: 'upperCase',
      message: 'An uppercase letter', selected: false

    },
    {
      name: 'lowerCase',
      message: 'A lowercase letter', selected: false
    },
    {
      name: 'number',
      message: 'A number', selected: false
    },
    {
      name: 'specialCharacter',
      message: 'A special character',
      terms: '~ ! @ # $ % ^ & * ( ) [ { }', selected: false
    },
  ];
  cannotHaveValidation = [
    {
      name: 'sequentialCharacters',
      message: 'More than two sequential characters',
      terms: 'Like 123 or ABC', selected: false
    },
    {
      name: 'repeatingCharacters',
      message: 'More than two repeating characters',
      terms: 'Like 111 or AAA', selected: false
    },
    {
      name: 'month',
      message: 'Abbreviations for months',
      terms: 'Like Jan, Feb, or Mar', selected: false
    },
    {
      name: 'day',
      message: 'Abbreviations for days',
      terms: 'Like Mon, Tue, or Wed', selected: false
    },
    {
      name: 'restricted',
      message: 'Restricted terms',
      terms:
        'Appl, Asdf, Basic, Cadam, Demo, Focus, Game, Net, New, Pas, Ros, Sign, Sys, Tso, Valid, Vtam, Log',
      selected: false
    },
    {
      name: 'specialCharacter',
      message: 'A special character',
      terms: '= _ - + ', selected: false
    },
  ];
  ngOnInit() {
    this.currentStep = null
    this.baeUserIDForm = this.formBuilder.group({
      userId: ['', [Validators.required, Validators.minLength(4),
      Validators.maxLength(40)]],

    });
    console.log('srepopopop', this.dataShare.step)
    this.nextStep(this.dataShare.step);
    this.baePasswordForm = this.formBuilder.group({

      password: ['', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(40)
      ]]
    });

    this.isChecked=false;
  }

  get userIDForm(): { [key: string]: AbstractControl } {
    return this.baeUserIDForm.controls;
  }
  get passwordForm(): { [key: string]: AbstractControl } {
    return this.baePasswordForm.controls;
  }
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.baeUserIDForm.invalid) {
      return;
    } else {
      this.submitted = false;

      this.loading = false;
    }
  }
  onLoginSubmit() {
    if (this.baePasswordForm.invalid) {
      return;
    }
  }
  onClickSendCodeNext() {
    this.selected = 1;
  }
  onReturnToLogin() {
    this.router.navigate(['/employer/login']);
  }
  sendCodeform = this.formBuilder.group({
    sendCodeType: new FormControl('email', Validators.required)
  });

  get form() {
    return this.sendCodeform.get('sendCodeType');
  }

  onSubmitSendCode() {
    this.selected = 2;
  }
  onOtpChange(code: any) {
    this.otpCode = code;
  }
  onContinueNext() {
        this.setCallBack(); 
  }
  async onSubmitPassword() {
    this.selected = 3;
    this.setCallBack();
    this.backupStep = this.currentStep
    this.currentStep= await FRAuth.next(this.currentStep)
    if(this.currentStep?.payload?.callbacks && this.currentStep?.payload?.callbacks.length > 0 && this.currentStep?.payload?.callbacks[0]?.output[0]?.value) {
      this.errorpwd =true;
      let result = this.currentStep?.payload?.callbacks[0]?.output[0]?.value
      result = JSON.parse(result)
      if(result?.policyRequirements[0]?.policyRequirement === "IS_NEW") {
        this.notifyService.showError("New password cannot be the same as your previous password", "Invalid Password")
      }else if(result?.policyRequirements[0]?.policyRequirement=== "CHARS_NOT_ALLOWED"){
        this.notifyService.showError("Password cannot contain certain characters", "Invalid Password")
      }else if(result?.policyRequirements[0]?.policyRequirement=== "SEQ_CHARS_NOT_ALLOWED") {
        this.notifyService.showError('More than two sequential characters are not allowed', "Invalid Password")
      }else if(result?.policyRequirements[0]?.policyRequirement=== "CONSECUTIVE_DIGITS_NOT_ALLOWED") {
        this.notifyService.showError('Consecutive digits Not Allowed', "Invalid Password")
      }else if(result?.policyRequirements[0]?.policyRequirement=== "REPEATED_CHARS_NOT_ALLOWED"){
        this.notifyService.showError("Password cannot contain Consecutive characters", "Invalid Password")
      }else if(result?.policyRequirements[0]?.policyRequirement=== "CANNOT_CONTAIN_OTHERS") {
        this.notifyService.showError('Password can not contain your first name, last name, userid or BCBS Plan State', "Invalid Password")
      }else if(result.policyRequirements[0].params.disallowedFields[0]=== "givenName") {
        this.notifyService.showError("Password cannot contain a First Name or Last Name", "Invalid Password")
      }else if(result.policyRequirements[0].params.disallowedFields[0]=== "sn") {
        this.notifyService.showError("Password cannot contain a First Name or Last Name", "Invalid Password")
      }else if(result.policyRequirements[0].params.disallowedFields[0]=== "hcscUserNameSuffix"){
        this.notifyService.showError("Password cannot contain user id", "Invalid Password")
      }else {
        this.notifyService.showError(result?.policyRequirements[0]?.policyRequirement, "Invalid Password")
      }
    }
    if(this.currentStep?.payload.successUrl) {
      this.errorpwd =true;
      this.notifyService.showSuccess("Password Changed Successfully", "Success")
      this.currentStep = null
      this.onReturnToLogin();
    }

  }

  passwordChange(event: any) {
    this.passwordLenth = event.length;
    //Validate minimum length

    if (event.length >= 8) {
      this.mustHaveValidation[0].selected = true;
    } else {
      this.mustHaveValidation[0].selected = false;
    }
    //Validate capital latters
    var CapitalLetters = /[A-Z]/g;
    if (event.match(CapitalLetters)) {
      this.mustHaveValidation[1].selected = true;
    } else {
      this.mustHaveValidation[1].selected = false;
    }
    //Validate lowercase latters
    var lowerCaseLetters = /[a-z]/g;
    if (event.match(lowerCaseLetters)) {
      this.mustHaveValidation[2].selected = true;

    } else {
      this.mustHaveValidation[2].selected = false;
    }

    //Validate number
    var numbers = /[0-9]/g;
    if (event.match(numbers)) {
      this.mustHaveValidation[3].selected = true;

    } else {
      this.mustHaveValidation[3].selected = false;
    }
    //Validate Special character
    var numbers = /[~!@#$%^&*()[{}]/;
    if (event.match(numbers)) {
      this.mustHaveValidation[4].selected = true;

    } else {
      this.mustHaveValidation[4].selected = false;
    }

    
    //can't have validations
    //sequentials
    let str = '~ ! @ # $ % ^ & * ( ) [ { }';

      for (const character in event) {
      if (
        String.fromCharCode(event.charCodeAt(character) + 1) ===
        event[+character + 1] &&
        !str.includes(event[character]) &&
        String.fromCharCode(event.charCodeAt(character) + 2) ===
        event[+character + 2]
      ) {
        this.cannotHaveValidation[0].selected = true;
      } else {
        this.cannotHaveValidation[0].selected = false;
      }
    }
    if (this.cannotHaveValidation[0].selected = false) {
      for (const character in event) {
        if (
          +event[+character + 1] === +event[character] + 1 &&
          +event[+character + 2] === +event[character] + 2
        ) {
          this.cannotHaveValidation[0].selected = true;
        } else {
          this.cannotHaveValidation[0].selected = false;
        }
      }
    }
    if (/(.)\1\1/.test(event)) {
      this.cannotHaveValidation[1].selected = true;
    } else {
      this.cannotHaveValidation[1].selected = false;
    }

    // Check for sequential numerical characters
    var test = (x: any) => !isNaN(x);
    var check = (x: any, y: any, i: any) => x + i === y;

    for (var i = 0; i < event.length - 2; i++) {
      if (test(event[i])) {
        if (test(event[i + 1]) && test(event[i + 2])) {
          if (check(Number(event[i]), Number(event[i + 1]), 1) &&
            check(Number(event[i]), Number(event[i + 2]), 2)) {
            this.cannotHaveValidation[0].selected = true;
          } else {
          }
        }
      } else if (!test(event[i + 1]) && !test(event[i + 2])) {
        if (check(event.charCodeAt(i), event.charCodeAt(i + 1), 1) &&
          check(event.charCodeAt(i), event.charCodeAt(i + 2), 2)) {
          this.cannotHaveValidation[0].selected = true;
        } 
      }
    }
   
    let monthsAbb = moment.monthsShort();

    if (monthsAbb.find(month => (event.toUpperCase()).match(month.toUpperCase()))) {
      this.cannotHaveValidation[2].selected = true;
    } else {
      this.cannotHaveValidation[2].selected = false;
    }
    let daysAbb = Array.apply(null, Array(7)).map(function (_, i) {
      return moment(i, 'e')
        .startOf('week')
        .isoWeekday(i + 1)
        .format('ddd');
    });

    if (daysAbb.find(day => (event.toUpperCase()).match(day.toUpperCase()))) {
      this.cannotHaveValidation[3].selected = true;
    } else {
      this.cannotHaveValidation[3].selected = false;
    }
    let words: string[] =
      ['SYS|LOG|TSO|PAS|NEW|NET|ROS|XXX|SIGN|VTAM|DEMO|ASDF|1234|BASIC|FOCUS|CADAM|VALID|GAME|APPL'];
    words = words[0].split('|');
    if (words.find((restricted: any) => (event.toUpperCase()).match(restricted.toUpperCase()))) {
      this.cannotHaveValidation[4].selected = true;
    } else {
      this.cannotHaveValidation[4].selected = false;
    } 
     //Validate Special character
   let str1 = '_  -+  =';
    for (const character in event) {
      if ( str1.includes(event[character]) )
       {
        this.cannotHaveValidation[5].selected = true;
      } else {
        this.cannotHaveValidation[5].selected = false;
      }
    }
   this.isSubmitButtonDisabled1 = !this.mustHaveValidation.every(x => x.selected) ;
    this.isSubmitButtonDisabled2 = this.cannotHaveValidation.some(x => x.selected) ;
     
  }

  findAccount() {
    
    this.setCallBack()

  }


  setCallBack() {
    console.log('checking call back')
    let urlArray = window.location.href.split(".com");
    this.corpCode = urlArray.length == 1 ? 'il' : urlArray[0].slice(-2);
    let i = 0

if(this.errorpwd) {
  this.currentStep = this.backupStep
}
    this.currentStep?.callbacks?.forEach(callback => {
      i++
      switch (callback.getType()) {
        case CallbackType.NameCallback: {
          (callback as NameCallback).setName(`${this.corpCode.toUpperCase()}1.EMP.${this.baeUserIDForm.value.userId.trim()}`);
          this.user = this.baeUserIDForm.value.userId

          break;
        }


        case CallbackType.PasswordCallback: {
          if (callback.payload.output[0].value === "One Time Password") {
            if(this.otpCode==null || this.otpCode==""){
              this.otpCode="000000000";
            }
            (callback as PasswordCallback).setPassword(this.otpCode);
          }
          else {
            (callback as PasswordCallback).setPassword(this.baePasswordForm.value.password);
          }


          break;
        }

        case CallbackType.StringAttributeInputCallback: {
          console.log('callback', callback, this.currentStep)
          if (callback.payload.input[0].name === "IDToken3") {
            (callback as AttributeInputCallback<any>).setInputValue(this.corpCode.toUpperCase() + '1', 0);
          }
          if (callback.payload.input[0].name === "IDToken2") {
            (callback as AttributeInputCallback<any>).setInputValue(environment.BAE_APP, 0);
          }


          break;

        }


        default: {
          console.error('Unrecognized callback type.');
          break;
        }
      }


    })
    if(this.baePasswordForm.value.password) {
      console.log('test')
    } else{
      this.nextStep(this.currentStep)
    }
  }

  handleDevice(type) {
    this.codeType = type
  }

  handleSelection() {
    this.codeType = this.sendCodeform.controls.sendCodeType.value
    this.choiceCallback(this.currentStep, this.codeType);
  }

  handleResend() {
    this.notifyService.showSuccess(`Code sent to ${this.codeType}`, "Success")
    this.choiceCallback(this.choiceCB, this.codeType).then(res=>{

    });
  }

  async choiceCallback(step: FRStep, codeSelected: string) {
    this.choiceCB= step
    step.callbacks?.forEach((callback, index) => {


      if (callback.getType() === "ChoiceCallback") {

        if(callback.payload.output.length==1 && callback.payload.output.values[0]==="Resend" ){
          (callback as ChoiceCallback).setInputValue(0);
        }
        else if (callback.payload.input[0].name === 'IDToken1') {
          let optedType = codeSelected === 'phone' ? 1 : 0;


          (callback as ChoiceCallback).setInputValue(optedType);
        }
      }
    });

    this.currentStep = await FRAuth.next(step);
   return this.currentStep;
  }

  async nextStep(step: FRStep) {
    
    this.currentStep = await FRAuth.next(step);
    if(this.currentStep.type === "LoginFailure") {
      if(this.currentStep.payload?.message === 'Failed - User Not found'){
      this.notifyService.showError('Error-code : 100 <br/> For assistance, call our Internet Help Desk at 1-888-706-0583', "Unsuccessful")
      }else{
	this.notifyService.showError(this.currentStep.payload?.message, "Unsuccessful")
	
	}
      this.onReturnToLogin()
    } else {
      if(this.currentStep?.payload?.callbacks && this.currentStep?.payload?.callbacks.length > 0 && this.currentStep?.payload?.callbacks[0]?.type === "ChoiceCallback") {

        if(this.currentStep?.payload?.callbacks[0]?.output[0].value.includes("The one-time verification code is incorrect")) {
          this.notifyService.showError("", this.currentStep?.payload?.callbacks[0]?.output[0].value+'Please try again or click resend for new code')
          this.currentStep = step
        }else{
          this.selected = 1
        }
        if(this.currentStep?.payload?.callbacks[0]?.output[1].value.length === 1) {
          this.ismulti = false
          this.singleVal = this.currentStep?.payload?.callbacks[0]?.output[1].value[0]
        } else {
          this.ismulti = true
        }
        this.email = this.currentStep?.payload?.callbacks[0]?.output[1].value[0].split(':')[1]
        this.phone = this.currentStep?.payload?.callbacks[0]?.output[1].value[1].split(':')[1]
      } else if(this.currentStep?.payload?.callbacks && this.currentStep?.payload?.callbacks.length > 0 && this.currentStep?.payload?.callbacks[0]?.type === "PasswordCallback") {
        this.selected = 3
      }
  
    }


  }

}
