import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpErrorResponse, HttpParams} from '@angular/common/http';
import{ObjectUnsubscribedError,throwError}from 'rxjs';
import {catchError} from 'rxjs/operators';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class MainService {
  
 baseUrl:any; 
 httpOptions = {
  headers: new HttpHeaders({
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  
    'Access-Control-Allow-Origin':'*',
    'Access-Control-Allow-Methods':'GET',
    'Access-Control-Allow-Headers':'application/json',
    'X-Requested-With':'*'
  }),
  responseType: 'text' as 'json'
}
 headers = new HttpHeaders({
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Headers': 'Content-Type',
  'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
  'X-Requested-With':'*'

});

private url = 'http://jsonplaceholder.typicode.com/posts';
  constructor(private httpClient:HttpClient) {
    //this.baseUrl=environment.url;
   }
  getBaeTermsData(code){
    if(code === 'il') return this.httpClient.get(environment.BAE_TOU_IL);
    if(code === 'tx') return  this.httpClient.get(environment.BAE_TOU_TX);
    if(code === 'mt') return this.httpClient.get(environment.BAE_TOU_MT);
    if(code === 'ok') return  this.httpClient.get(environment.BAE_TOU_OK);
    if(code === 'nm') return  this.httpClient.get(environment.BAE_TOU_NM);
  }

  getBapTermsData(code){
    if(code === 'il') return this.httpClient.get(environment.BAP_TOU_IL);
    if(code === 'tx') return  this.httpClient.get(environment.BAP_TOU_TX);
    if(code === 'mt') return this.httpClient.get(environment.BAP_TOU_MT);
    if(code === 'ok') return  this.httpClient.get(environment.BAP_TOU_OK);
    if(code === 'nm') return  this.httpClient.get(environment.BAP_TOU_NM);
  }

  postData(body:any,url:any){
    return this.httpClient.post(this.baseUrl+url,body);
  } 

  postDataByUrl(body:any,url:any){
    return this.httpClient.post(url,body);
  } 

  postDataByUrlAndHeaders(body:any,url:any, httpOptions:any){
    return this.httpClient.post(url,body, httpOptions);
  } 

   getData(url:any){
    return this.httpClient.get(this.baseUrl+url);
  }


  getPosts(){
    return this.httpClient.get(this.url);
  }

  getIPAddress(userId: any, corpCd: any) {
    console.log('userId', userId, 'corpCd', corpCd);
    const parameters = new HttpParams().set("userId", userId)
      .set("corpCd", corpCd)
    return this.httpClient.get("/groupauthenticator/getIPAddress", { params: parameters });
  }
  getSSOIDDetails(accountNum:any,state:any){
    const parameters = new HttpParams().set("accountNum", accountNum)
      .set("state", state)
    return this.httpClient.get("/groupauthenticator/getSSOIDDetails", { params: parameters });
  }
  
   resendBAEEmail(corpCode: any,userName:any) {
    const parameters = new HttpParams()
    .set("appName", environment.BAE_APP_ID)
    .set("corpCode", corpCode +1)
    .set("userName", userName)
    .set("resendVerification", true)
    .set("url" , environment.BAE_RESEND_EMAIL)
    return this.httpClient.get("/groupauthenticator/sendEmail", { params: parameters });
   
  }
  
   resendBAPEmail(corpCode: any,userName:any) {
    const parameters = new HttpParams()
    .set("appName", environment.BAP_APP_ID)
    .set("corpCode", corpCode +1)
    .set("userName", userName)
    .set("resendVerification", true)
    .set("url" , environment.BAE_RESEND_EMAIL)
    return this.httpClient.get("/groupauthenticator/sendEmail", { params: parameters });
   
  }
  
   getRemoteIPAddress(userId: any){
	const parameters = new HttpParams()
	.set("userId", userId)
	return this.httpClient.get("/groupauthenticator/getRemoteIPAddress", { params: parameters });
	
}

}
