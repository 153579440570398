import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ProducerValidate } from 'src/app/models/bap-validate-account';
import { NotificationService } from 'src/app/services/notification.service';
import { MainService } from '../../services/main.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DataShare } from 'src/app/services/datashare';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-bap-information',
  templateUrl: './bap-information.component.html',
  styleUrls: ['./bap-information.component.css']
})
export class BapInformationComponent implements OnInit {
  showPassword = false;
  producerInfoForm!: FormGroup;
  producerLoginForm!: FormGroup;
  validateResp: any
  submitted = false;
  selected: any = 0;
  passwordLenth = 0;
  jsonData: any;
  posts: any;
  versionId: any;
  corpCode: any = ''
  pNum: string = '';
  isSubmitButtonDisabled1 = true;
  isSubmitButtonDisabled2 = true;
  userrId: any;
  @BlockUI() blockUI: NgBlockUI;
  constructor(private formBuilder: FormBuilder,
    private notifyService: NotificationService,
    private dataShare: DataShare,
    private route: ActivatedRoute,
    private router: Router, private mainService: MainService) { }



  emailPattern = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$";
  mustHaveValidation = [
    { name: 'minAndMax', message: '8-40 characters', selected: false },
    {
      name: 'upperCase',
      message: 'An uppercase letter', selected: false

    },
    {
      name: 'lowerCase',
      message: 'A lowercase letter', selected: false
    },
    {
      name: 'number',
      message: 'A number', selected: false
    },
    {
      name: 'specialCharacter',
      message: 'A special character',
      terms: '~ ! @ # $ % ^ & * ( ) [ { }', selected: false
    },
  ];
  cannotHaveValidation = [
    {
      name: 'sequentialCharacters',
      message: 'More than two repeated characters',
      terms: 'Like 123 or ABC', selected: false
    },
    {
      name: 'repeatingCharacters',
      message: 'More than two repeating characters',
      terms: 'Like 111 or AAA', selected: false
    },
    {
      name: 'month',
      message: 'Abbreviations for months',
      terms: 'Like Jan, Feb, or Mar', selected: false
    },
    {
      name: 'day',
      message: 'Abbreviations for days',
      terms: 'Like Mon, Tue, or Wed', selected: false
    },
    {
      name: 'restricted',
      message: 'Restricted terms',
      terms:
        'Appl, Asdf, Basic, Cadam, Demo, Focus, Game, Net, New, Pas, Ros, Sign, Sys, Tso, Valid, Vtam, Log, Xxx, 1234',
      selected: false
    },
    {
      name: 'specialCharacter',
      message: 'A special character',
      terms: '= _ - + ', selected: false
    },
    {
      name: 'name',
      message: 'Your first or last name, or username', selected: false
    },
    {
      name: 'password',
      message: 'Last 24 passwords cannot be used', selected: false
    }
  ];
  producerFormValid: any = false;
  ngOnInit() {
    this.versionId = this.route.snapshot.paramMap.get('version')
    let urlArray = window.location.href.split(".com");
    this.corpCode = urlArray.length == 1 ? 'il' : urlArray[0].slice(-2);
    this.producerInfoForm = this.formBuilder.group({
      producerNumber: ['', [
        Validators.required,
        Validators.minLength(9),
        Validators.maxLength(9)
      ]],
      nationalProducerNumber: ['', [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10)
      ]],
      zipCode: ['', [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(5), Validators.pattern("^[0-9]*$")
      ]]
    });
    this.producerLoginForm = this.formBuilder.group({
      userId: ['', [Validators.required, Validators.minLength(5),
      Validators.maxLength(20)]],
      password: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(40)
      ]]
    });
  }

  // convenience getter for easy access to form fields
  get regForm(): { [key: string]: AbstractControl } {
    return this.producerInfoForm.controls;
  }
  get regForm2(): { [key: string]: AbstractControl } {
    return this.producerLoginForm.controls;
  }
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.producerInfoForm.invalid) {
      this.producerFormValid = false;
      return;
    } else {
      this.submitted = false;
      this.producerFormValid = true;
    }

  }

  onLoginSubmit() {
    this.submitted = true;
    this.createLogin()
  }
  passwordChange(event: any) {
    this.passwordLenth = event.length;
    //Validate minimum length
    if (event.length >= 8) {
      this.mustHaveValidation[0].selected = true;
    } else {
      this.mustHaveValidation[0].selected = false;
    }
    //Validate capital latters
    var CapitalLetters = /[A-Z]/g;
    if (event.match(CapitalLetters)) {
      this.mustHaveValidation[1].selected = true;
    } else {
      this.mustHaveValidation[1].selected = false;
    }
    //Validate lowercase latters
    var lowerCaseLetters = /[a-z]/g;
    if (event.match(lowerCaseLetters)) {
      this.mustHaveValidation[2].selected = true;

    } else {
      this.mustHaveValidation[2].selected = false;
    }

    //Validate number
    var numbers = /[0-9]/g;
    if (event.match(numbers)) {
      this.mustHaveValidation[3].selected = true;

    } else {
      this.mustHaveValidation[3].selected = false;
    }
    //Validate Special charector
    var numbers = /[~!@#$%^&*()[{}]/;
    if (event.match(numbers)) {
      this.mustHaveValidation[4].selected = true;

    } else {
      this.mustHaveValidation[4].selected = false;
    }

    //can't have validations
    //sequentials
    let str = '~ ! @ # $ % ^ & * ( ) [ { }';

    for (const character in event) {
      if (
        String.fromCharCode(event.charCodeAt(character) + 1) ===
        event[+character + 1] &&
        !str.includes(event[character]) &&
        String.fromCharCode(event.charCodeAt(character) + 2) ===
        event[+character + 2]
      ) {
        this.cannotHaveValidation[0].selected = true;
      } else {
        this.cannotHaveValidation[0].selected = false;
      }
    }
    if (this.cannotHaveValidation[0].selected = false) {
      for (const character in event) {
        if (
          +event[+character + 1] === +event[character] + 1 &&
          +event[+character + 2] === +event[character] + 2
        ) {
          this.cannotHaveValidation[0].selected = true;
        } else {
          this.cannotHaveValidation[0].selected = false;
        }
      }
    }
    if (/(.)\1\1/.test(event)) {
      this.cannotHaveValidation[1].selected = true;
    } else {
      this.cannotHaveValidation[1].selected = false;
    }

    // Check for sequential numerical characters
    var test = (x: any) => !isNaN(x);
    var check = (x: any, y: any, i: any) => x + i === y;

    for (var i = 0; i < event.length - 2; i++) {
      if (test(event[i])) {
        if (test(event[i + 1]) && test(event[i + 2])) {
          if (check(Number(event[i]), Number(event[i + 1]), 1) &&
            check(Number(event[i]), Number(event[i + 2]), 2)) {
            this.cannotHaveValidation[0].selected = true;
          } else {
            // this.cannotHaveValidation[0].selected = false;
          }
        }
      } else if (!test(event[i + 1]) && !test(event[i + 2])) {
        if (check(event.charCodeAt(i), event.charCodeAt(i + 1), 1) &&
          check(event.charCodeAt(i), event.charCodeAt(i + 2), 2)) {
          this.cannotHaveValidation[0].selected = true;
        } else {
          // this.cannotHaveValidation[0].selected = false;
        }
      }
    }
    let monthsAbb = moment.monthsShort();

    if (monthsAbb.find(month => (event.toUpperCase()).match(month.toUpperCase()))) {
      this.cannotHaveValidation[2].selected = true;
    } else {
      this.cannotHaveValidation[2].selected = false;
    }
    let daysAbb = Array.apply(null, Array(7)).map(function (_, i) {
      return moment(i, 'e')
        .startOf('week')
        .isoWeekday(i + 1)
        .format('ddd');
    });

    if (daysAbb.find(day => (event.toUpperCase()).match(day.toUpperCase()))) {
      this.cannotHaveValidation[3].selected = true;
    } else {
      this.cannotHaveValidation[3].selected = false;
    }
    let words: string[] =
      ['SYS|LOG|TSO|PAS|NEW|NET|ROS|XXX|SIGN|VTAM|DEMO|ASDF|1234|BASIC|FOCUS|CADAM|VALID|GAME|APPL'];
    words = words[0].split('|');
    if (words.find((restricted: any) => (event.toUpperCase()).match(restricted.toUpperCase()))) {
      this.cannotHaveValidation[4].selected = true;
    } else {
      this.cannotHaveValidation[4].selected = false;
    }
     //Validate Special character
    let str1 = '_  -+  =';
    for (const character in event) {
      if ( str1.includes(event[character]) )
       {
        this.cannotHaveValidation[5].selected = true;
      } else {
        this.cannotHaveValidation[5].selected = false;
      }
    }
    let val = event.toLowerCase()
    if (val.includes(this.validateResp?.firstName?.toLowerCase()) ||
      val.includes(this.validateResp?.lastName?.toLowerCase()) ||
      val.includes(this.producerInfoForm.value.producerNumber.toLowerCase()) ||
      val.includes('il1') ||
      val.includes('tx1') ||
      val.includes('nm1') ||
      val.includes('ok1') ||
      val.includes('mt1')
    )
    {
      this.cannotHaveValidation[6].selected = true;
    } else {
      this.cannotHaveValidation[6].selected = false;
    }
    this.isSubmitButtonDisabled1 = !this.mustHaveValidation.every(x => x.selected) ;
    this.isSubmitButtonDisabled2 = this.cannotHaveValidation.some(x => x.selected) ;
  }
  onReturnToLogin() {
    this.router.navigate(['/producer/login']);
  }
  backtoStep0() {
    this.selected = 0;
  }
  backtoStep1() {
    this.selected = 1;
  }
  backtoStep2() {
    this.selected = 2;
  }
  restrictedTerms(pwd: any) {
    let words: string[] =
      ['SYS|LOG|TSO|PAS|NEW|NET|ROS|XXX|SIGN|VTAM|DEMO|ASDF|1234|BASIC|FOCUS|CADAM|VALID|GAME|APPL'];
    words = words[0].split('|');
    return words.find((restricted: any) => pwd.match(restricted.toUpperCase()));
  };

  handleProducer() {
    this.blockUI.start('Loading...');
    this.producerInfoForm.controls.producerNumber.setValue(this.producerInfoForm.value.producerNumber.trim());
    this.producerInfoForm.controls.nationalProducerNumber.setValue(this.producerInfoForm.value.nationalProducerNumber.trim());
    let bapRegister = new ProducerValidate(this.producerInfoForm.value.producerNumber.trim(), this.producerInfoForm.value.nationalProducerNumber.trim(), this.producerInfoForm.value.zipCode.trim())
    this.mainService.postDataByUrl(bapRegister, environment.ProducerValidationUri).subscribe((response) => {
      this.blockUI.stop();
      this.validateResp = response
      this.submitted = false; 

      let state=this.validateResp.state;
      if( this.corpCode.toLowerCase() === state.toLowerCase()){
      this.notifyService.showSuccess('Producer validated Successfully', 'Success')
      this.selected = 1
      }else{
        this.notifyService.showError('Registration Not Allowed', 'Unsuccessful')  
      }     
    },(errorResp) => {
      this.blockUI.stop();
      let errors = errorResp.error?.errors;
      let message = errors[0]?.message;
      if(message!= null){
        this.notifyService.showError(message, "Unsuccessful")
      }else{
        this.notifyService.showError("", "Unsuccessful")
      }

    });
  }

  createLogin() {
    this.blockUI.start('loading')
    let touAcceptedDate = this.dataShare.getTouAccepted();
    if( touAcceptedDate === undefined){
		touAcceptedDate =  new DatePipe('en-US').transform(new Date(), 'MM-dd-yyyy')
		console.debug('Tou came undefined : '+ touAcceptedDate);
	}
    let producerform = this.producerLoginForm.value;
    let userId = this.corpCode.toUpperCase() + '1.' + "BRK." + this.producerInfoForm.value.producerNumber.trim(); 
    const url = this.corpCode === 'il' ? environment.REGISTER_URL_BAP_IL
    : this.corpCode === 'tx' ? environment.REGISTER_URL_BAP_TX
    : this.corpCode === 'mt' ? environment.REGISTER_URL_BAP_MT
    : this.corpCode === 'ok' ? environment.REGISTER_URL_BAP_OK
    : this.corpCode === 'nm' ? environment.REGISTER_URL_BAP_NM : environment.REGISTER_URL_BAP_IL

    this.mainService.postDataByUrl({}, url).subscribe(
      (response: any) => {
        console.log(response)
        let reqobj = {
          ...response
        }

        reqobj.callbacks.forEach(callback => {
          if (callback.input.find(ip => ip.name === 'IDToken1')) {
            callback.input.find(ip => ip.name === 'IDToken1').value = userId;
          }
          if (callback.input.find(ip => ip.name === 'IDToken2')) {
            callback.input.find(ip => ip.name === 'IDToken2').value = producerform.password.trim();
          }
          if (callback.input.find(ip => ip.name === 'IDToken3')) {
            callback.input.find(ip => ip.name === 'IDToken3').value = this.validateResp.firstName;
          }
          if (callback.input.find(ip => ip.name === 'IDToken4')) {
            callback.input.find(ip => ip.name === 'IDToken4').value = this.validateResp.lastName;
          }
          if (callback.input.find(ip => ip.name === 'IDToken5')) {
            callback.input.find(ip => ip.name === 'IDToken5').value = this.validateResp.emailAddress;
          }
          if (callback.input.find(ip => ip.name === 'IDToken6')) {
            callback.input.find(ip => ip.name === 'IDToken6').value = this.corpCode.toUpperCase() + '1';
          }
          if (callback.input.find(ip => ip.name === 'IDToken7')) {
            let appRole;
            if (this.corpCode.toUpperCase() === 'IL') {
              appRole = environment.BAP_USER_ROLE.IL
            } else if (this.corpCode.toUpperCase() === 'NM') {
              appRole = environment.BAP_USER_ROLE.NM
            } else if (this.corpCode.toUpperCase() === 'OK') {
              appRole = environment.BAP_USER_ROLE.OK
            } else if (this.corpCode.toUpperCase() === 'TX') {
              appRole = environment.BAP_USER_ROLE.TX
            } else if (this.corpCode.toUpperCase() === 'MT') {
              appRole = environment.BAP_USER_ROLE.MT
            }
            callback.input.find(ip => ip.name === 'IDToken7').value = '[{\"hcscAppID\":\"APP00000061\",\"hcscAppRole\":' + appRole + '}]';
          }
          if (callback.input.find(ip => ip.name === 'IDToken8')) {
            callback.input.find(ip => ip.name === 'IDToken8').value =
              `{\"termsOfService\":{\"hcscAppID\":\"${environment.BAP_APP_ID}\",\"corpCode\":\"${this.corpCode.toUpperCase()}1\",\"acceptedDate\":\"${touAcceptedDate}\",\"version\":\"${this.versionId}\"}}`
          }
          if (callback.input.find(ip => ip.name === 'IDToken9')) {
            callback.input.find(ip => ip.name === 'IDToken9').value = this.producerInfoForm.value.zipCode.trim();
          }
          if (callback.input.find(ip => ip.name === 'IDToken10')) {
            callback.input.find(ip => ip.name === 'IDToken10').value = this.validateResp.workPhone;
          }
          if (callback.input.find(ip => ip.name === 'IDToken11')) {
            callback.input.find(ip => ip.name === 'IDToken11').value = "";
          }
          if (callback.input.find(ip => ip.name === 'IDToken12')) {
            callback.input.find(ip => ip.name === 'IDToken12').value = this.validateResp.mobilePhone;
          }
          if (callback.input.find(ip => ip.name === 'IDToken13')) {
            callback.input.find(ip => ip.name === 'IDToken13').value = "";
          }
          if (callback.input.find(ip => ip.name === 'IDToken14')) {
            callback.input.find(ip => ip.name === 'IDToken14').value = "";
          }
          if (callback.input.find(ip => ip.name === 'IDToken15')) {
            callback.input.find(ip => ip.name === 'IDToken15').value = "";
          }
          if (callback.input.find(ip => ip.name === 'IDToken16')) {
            callback.input.find(ip => ip.name === 'IDToken16').value = "";
          }
          if (callback.input.find(ip => ip.name === 'IDToken17')) {
            callback.input.find(ip => ip.name === 'IDToken17').value = "Test Account";
          }


        });
        this.mainService.postDataByUrl(reqobj, url).subscribe((res: any) => {
          if (res['successUrl'] && res['successUrl'].length > 0) {
            this.submitted = false;
            this.blockUI.stop();
            this.selected = 2;
            this.notifyService.showSuccess("", "Success");
          } else {
            	  this.blockUI.stop();
	              let result = res.callbacks[0].type
	               if( result === "ValidatedCreateUsernameCallback"){
					let result1 = res.callbacks.filter(t => t.type === "ValidatedCreateUsernameCallback")
					let step1 = result1[0]?.output[1]?.value
		          	let step2;
		          if(step1.length > 0) {step2 = JSON.parse(step1)}
		              if (step2.policyRequirement === 'UNIQUE') {
		                this.blockUI.stop();
		                this.notifyService.showError('User Already Exists', "Unsuccessful");
		              }else {
		                this.blockUI.stop();
		                this.notifyService.showError('Password is not allowed', "Unsuccessful");
		                }
					}else if( result === "TextOutputCallback"){
						let value = JSON.parse(res.callbacks[0].output[0].value)
						if( value?.policyRequirements[0]?.policyRequirement === 'CONSECUTIVE_DIGITS_NOT_ALLOWED')
						{
							this.blockUI.stop();
		                	this.notifyService.showError('Consecutive digits not allowed', "Unsuccessful");
						}else if(value?.policyRequirements[0]?.policyRequirement === 'SEQ_CHARS_NOT_ALLOWED'){
							this.blockUI.stop();
		                	this.notifyService.showError('More than two sequential characters are not allowed', "Unsuccessful");
						}else if(value?.policyRequirements[0]?.policyRequirement === 'CHARS_NOT_ALLOWED'){
							this.blockUI.stop();
		                	this.notifyService.showError('Password cannot contain certain characters', "Unsuccessful");
						}else if(value?.policyRequirements[0]?.policyRequirement === 'REPEATED_CHARS_NOT_ALLOWED'){
							this.blockUI.stop();
		                	this.notifyService.showError('More than 2 consecutive characters not allowed', "Unsuccessful");
						}else{
							 this.blockUI.stop();
		                	 this.notifyService.showError('Password is not allowed', "Unsuccessful");
						}
						
					}else {
		                this.blockUI.stop();
		                this.notifyService.showError(res['message'], "Unsuccessful");
		            }
		   }

        }, (error) => {
          this.blockUI.stop();
          console.log('errorrr', error)
          this.notifyService.showError(error['message'], "Unsuccessful");
        });
      }
    )
  }
}
