import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BapLoginComponent } from './bap-login/bap-login.component';
import { BapVerifyAccountComponent } from './bap-verify-account/bap-verify-account.component';

import {FlexLayoutModule} from '@angular/flex-layout';

import { BapEmailVerifyComponent } from './bap-email-verify/bap-email-verify.component';
import { BapCodeVerifyComponent } from './bap-code-verify/bap-code-verify.component';
import { NgOtpInputModule } from  'ng-otp-input';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BapFooterComponent } from './bap-footer/bap-footer.component';
import { BapHeaderComponent } from './bap-header/bap-header.component';
import { BapLeftcontentComponent } from './bap-leftcontent/bap-leftcontent.component';
import { BaeLoginComponent } from './bae-login/bae-login.component';
import { BaeLeftcontentComponent } from './bae-leftcontent/bae-leftcontent.component';
import { BaeFooterComponent } from './bae-footer/bae-footer.component';
import { BaeVerifyAccountComponent } from './bae-verify-account/bae-verify-account.component';
import { BaeEmailVerifyComponent } from './bae-email-verify/bae-email-verify.component';
import { BaeCodeVerifyComponent } from './bae-code-verify/bae-code-verify.component';
import { BaeTermsConditionsComponent } from './bae-terms-conditions/bae-terms-conditions.component';
import { BapInformationComponent } from './bap-information/bap-information.component';
import { BaeHeaderComponent } from './bae-header/bae-header.component';
import { BapForgotPasswordComponent } from './bap-forgot-password/bap-forgot-password.component';
import { BaeInformationComponent } from './bae-information/bae-information.component';
import { BaeForgotPasswordComponent } from './bae-forgot-password/bae-forgot-password.component';
import { BapForgotUseridComponent } from './bap-forgot-userid/bap-forgot-userid.component';
import { BaeForgotUseridComponent } from './bae-forgot-userid/bae-forgot-userid.component';
import { BapTermsConditionsComponent } from './bap-terms-conditions/bap-terms-conditions.component';
import { BaeUpdatePasswordComponent } from './bae-update-password/bae-update-password.component';
import { BapUpdatePasswordComponent } from './bap-update-password/bap-update-password.component';
import { SessionTimeoutComponent } from './session-timeout/session-timeout.component';
import { CancelAccountComponent } from './cancel-account/cancel-account.component';
import { HiddenCallbackComponent } from './hidden-callback/hidden-callback.component';
import { TextCallbackComponent } from './text-callback/text-callback.component';


@NgModule({
  declarations: [
    BapLoginComponent,
    BapVerifyAccountComponent,
    BapEmailVerifyComponent,
    BapCodeVerifyComponent,
    BapFooterComponent,
    BapHeaderComponent,
    BapLeftcontentComponent,
    BaeLoginComponent,
    BaeLeftcontentComponent,
    BaeFooterComponent,
    BaeVerifyAccountComponent,
    BaeEmailVerifyComponent,
    BaeCodeVerifyComponent,
    BaeTermsConditionsComponent,
    BapInformationComponent,
    BaeHeaderComponent,
    BapForgotPasswordComponent,
    BaeInformationComponent,
    BaeForgotPasswordComponent,
    BapForgotUseridComponent,
    BaeForgotUseridComponent,
    BapTermsConditionsComponent,
    BaeUpdatePasswordComponent,
    BapUpdatePasswordComponent,
    SessionTimeoutComponent,
    CancelAccountComponent,
    HiddenCallbackComponent,
    TextCallbackComponent,
    
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,NgOtpInputModule,
    NgbModule
  ],
  exports:[
    ReactiveFormsModule,
    BapLoginComponent,
    BapVerifyAccountComponent  ],
  providers: [],
})
export class ComponentsModule { }
