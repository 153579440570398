<div class="body"> 
    <app-bae-header></app-bae-header>
     <main>
        <div class="fiber-grid resp-margin">
            <div class="fiber-grid__row">
                <div class="fiber-grid__col fiber-grid__col--xs-4 fiber-grid__col--md-5">
                      <app-bae-leftcontent></app-bae-leftcontent>
                    </div>
    
                    <div class="baep-form-grid fiber-grid__col fiber-grid__col--xs-4  fiber-grid__col--offset-md-1 fiber-grid__col--md-5">
                        <article *ngIf="selected==0" class="fiber-box">
                            <form class="baep-reg-form fiber-form" aria-labelledby="Baep_Reg_Form"
                        [formGroup]="baeUserForm" (ngSubmit)="onSubmit()" >

                            <fieldset class="fiber-fieldset fiber-bw-0 ">

                                <p
                                    class="fiber-d-flex fiber-text--size-2 fiber-text--weight-bold fiber-text--color-gray-4">

                                    <div class=" ">
                                        <span  class="fiber-icon--size-1 fiber-icon--name-chevron-left-blue-primary  fiber-mr-1">
    
                                        </span>
    
                                        <span class="fiber-text--weight-normal fiber-text--color-blue-primary"
                                            (click)="onReturnToLogin()">Back</span><br>
                                  
                                </div>
                              
                                <h1
                                    class="baep-verify-heading fiber-text--size-5 fiber-text--weight-bold fiber-text--color-gray-5 fiber-pt-3 fiber-pb-1">
                                    Forgot User ID</h1>
                                    <p
                                    class="fiber-text fiber-text--size-2 fiber-text--color-text-dark fiber-pt-1 fiber-pb-4">
                                   To get your User Id, enter the email address associated with the account.</p>
                                <div
                                    class="fiber-box fiber-s-1 fiber-br-2 fiber-mr-1 fiber-ml-1 fiber-mb-5 fiber-mt-1 fiber-p-3">
                                    <div class="form-group">
                                        <label for="email"><b>*Email Address</b></label>
                                        <input type="text" formControlName="email" class="form-control" [(ngModel)] = "email"
                                            [ngClass]="{ 'is-invalid': submitted && userForm.email.errors }" />
                                        <div *ngIf="submitted && userForm.email.errors" class="invalid-feedback">
                                            <div *ngIf="userForm.email.errors.required">Email Address is required
                                            </div>
                                            <div *ngIf="userForm.email.errors.pattern">
                                                Please enter Valid Email Address
                                            </div>

                                        </div>
                                    </div>

                                </div>

                            </fieldset>
                            <div class="fiber-pt-3 fiber-ta-right">
                                <button name="baep-reg-continue" 
                                    class="fiber-button fiber-button--size-1 fiber-button--primary fiber-text--weight-bold fiber-mb-3 fiber-ta-right">
                                    <span class="fiber-button__text">Send Email</span>
                                </button>
                            </div>

                        </form>
            
                        
                        </article>
                        <article *ngIf="selected==1" class="fiber-box">
                            <form class="baep-reg-form fiber-form" aria-labelledby="Baep_Reg_Form"
                        >
    
                            <fieldset class="fiber-fieldset fiber-bw-0 ">
    
                                <p
                                    class="fiber-d-flex fiber-text--size-2 fiber-text--weight-bold fiber-text--color-gray-4">
    
                                    <div class=" ">
                                        <span  class="fiber-icon--size-1 fiber-icon--name-chevron-left-blue-primary  fiber-mr-1">
    
                                        </span>
    
                                        <span class="fiber-text--weight-normal fiber-text--color-blue-primary"
                                            (click)="onReturnBackStep0()">Back</span><br>
                                  
                                </div>
                              
                                <h1
                                    class="baep-verify-heading fiber-text--size-5 fiber-text--weight-bold fiber-text--color-gray-5 fiber-pt-3 fiber-pb-1">
                                    Forgot User ID</h1>
                                    <p
                                    class="fiber-text fiber-text--size-2 fiber-text--color-text-dark fiber-pt-1 fiber-pb-4">
                                   If you have account, we sent an email to:<br/> {{baeUserForm.controls.email.value}}</p>
                              
    
                            </fieldset>
                            <div class=" fiber-pt-3 fiber-ta-right" >
                                <button name="baep-reg-continue"  (click)="onReturnToLogin()"
                                class="fiber-button fiber-button--size-1 fiber-button--primary fiber-text--weight-bold fiber-mb-3 fiber-ta-right">
                                <span class="fiber-button__text">Return To Log In</span>
                            </button>
                            </div>
    
                        </form>
            
                        
                        </article>
                    </div>
    
                </div>
    
            </div>
     </main>
     <app-bae-footer></app-bae-footer>
</div>


	
  




