import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BaeCodeVerifyComponent } from './components/bae-code-verify/bae-code-verify.component';
import { BaeEmailVerifyComponent } from './components/bae-email-verify/bae-email-verify.component';
import { BaeForgotPasswordComponent } from './components/bae-forgot-password/bae-forgot-password.component';
import { BaeForgotUseridComponent } from './components/bae-forgot-userid/bae-forgot-userid.component';
import { BaeInformationComponent } from './components/bae-information/bae-information.component';
import { BaeLoginComponent } from './components/bae-login/bae-login.component';
import { BaeTermsConditionsComponent } from './components/bae-terms-conditions/bae-terms-conditions.component';
import { BaeUpdatePasswordComponent } from './components/bae-update-password/bae-update-password.component';
import { BaeVerifyAccountComponent } from './components/bae-verify-account/bae-verify-account.component';
import { BapCodeVerifyComponent } from './components/bap-code-verify/bap-code-verify.component';
import { BapEmailVerifyComponent } from './components/bap-email-verify/bap-email-verify.component';
import { BapForgotPasswordComponent } from './components/bap-forgot-password/bap-forgot-password.component';
import { BapForgotUseridComponent } from './components/bap-forgot-userid/bap-forgot-userid.component';
import { BapInformationComponent } from './components/bap-information/bap-information.component';
import { BapLoginComponent } from './components/bap-login/bap-login.component';
import { BapTermsConditionsComponent } from './components/bap-terms-conditions/bap-terms-conditions.component';
import { BapUpdatePasswordComponent } from './components/bap-update-password/bap-update-password.component';
import { BapVerifyAccountComponent } from './components/bap-verify-account/bap-verify-account.component';
import { CancelAccountComponent } from './components/cancel-account/cancel-account.component';
import { SessionTimeoutComponent } from './components/session-timeout/session-timeout.component';

const routes: Routes = [
  {path: '',redirectTo: 'employer/login',pathMatch:'full'},
  {path:'producer/login',component: BapLoginComponent},
  {path:'producer/login?uid=',component: BapLoginComponent},
  {path:'producer/verify-account',component:BapVerifyAccountComponent},
  {path: 'producer/email-verify', component:BapEmailVerifyComponent},
  {path:'producer/code-verification',component:BapCodeVerifyComponent},
  {path:'producer/terms-conditions',component:BapTermsConditionsComponent},
  {path:'employer/login',component: BaeLoginComponent},
  {path:'employer/verify-account',component:BaeVerifyAccountComponent},
  {path:'employer/email-verify', component:BaeEmailVerifyComponent},
  {path:'employer/code-verification',component:BaeCodeVerifyComponent},
  {path:'employer/terms-conditions',component:BaeTermsConditionsComponent},
  {path:'producer/register',component:BapInformationComponent},
  {path:'producer/forgot-password',component:BapForgotPasswordComponent},
  {path:'employer/register',component:BaeInformationComponent},
  {path:'employer/forgot-password',component:BaeForgotPasswordComponent},
  {path:'producer/forgot-user',component:BapForgotUseridComponent},
  {path:'employer/forgot-user',component:BaeForgotUseridComponent},
  {path:'employer/update-password',component:BaeUpdatePasswordComponent},
  {path:'producer/update-password',component:BapUpdatePasswordComponent},
  {path:'employer/cancelAccount',component:CancelAccountComponent},
  {path:'employer/sessionTimeout',component:SessionTimeoutComponent}

];
@NgModule({
  imports: [RouterModule.forRoot(routes,{scrollPositionRestoration :'top',relativeLinkResolution: 'legacy', useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
