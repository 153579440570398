import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bae-email-verify',
  templateUrl: './bae-email-verify.component.html',
  styleUrls: ['./bae-email-verify.component.css']
})
export class BaeEmailVerifyComponent implements OnInit {

  constructor(private router: Router,) { }
  email: any;
  ngOnInit(): void {
    this.email.trim= "e*******@email.com";
  }
  sendCode() {
    this.router.navigate(['/employer/code-verification']);
  }

}
