import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DataShare } from 'src/app/services/datashare';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-bae-terms-conditions',
  templateUrl: './bae-terms-conditions.component.html',
  styleUrls: ['./bae-terms-conditions.component.css']
})
export class BaeTermsConditionsComponent implements OnInit {

  jsonData:any;
   posts:any;
   version: any;
  constructor(private mainService:MainService,  private router: Router,
    private dataShare:DataShare, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    let urlArray =window.location.href.split(".com");
    let locCode= urlArray.length ==1 ?'il':urlArray [0].slice(-2);
    this.mainService.getBaeTermsData(locCode).subscribe((res: any)=>{
      var doc:any = new DOMParser().parseFromString(res[0].labelDescription, "text/html");
     this.version = res[0].versionId
      this.jsonData= this.sanitizer.bypassSecurityTrustHtml(doc.firstChild.innerHTML);
    });
 
  }
  onReturnToLogin() {
    this.router.navigate(['/employer/login']);
  }
  onTermsAccept(){
    this.dataShare.setTouAccepted(new DatePipe('en-US').transform(new Date(), 'MM-dd-yyyy'));
    this.router.navigate(['/employer/register', {version: this.version}]);
  }
}
