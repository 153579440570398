import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bap-code-verify',
  templateUrl: './bap-code-verify.component.html',
  styleUrls: ['./bap-code-verify.component.css']
})
export class BapCodeVerifyComponent implements OnInit {
  isChecked =false;
  constructor() { }

  ngOnInit(): void {
    this.isChecked=false;
  }
  onOtpChange(code: any){

  }
  checkValue(event:any){
    if(event=='true'){
    const dynamicScript=['assets/js/DeviceFP.js'];
    for (let i=0; i<dynamicScript.length; i++){
      const node=document.createElement('script');
      node.src=dynamicScript[i];
      node.type='text/javascript';
      node.async=false;
      document.getElementsByTagName('head')[0].appendChild(node);
    }
    }
  }
}
