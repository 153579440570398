import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bae-footer',
  templateUrl: './bae-footer.component.html',
  styleUrls: ['./bae-footer.component.css']
})
export class BaeFooterComponent implements OnInit {
  corpCode: any;
  legalURL:any;
  state:any;

  constructor() { }

  ngOnInit(): void {
    let urlArray = window.location.href.split(".com");
    this.corpCode = this.getCorp(urlArray.length == 1 ? 'il' : urlArray[0].slice(-2));
    this.state=urlArray.length == 1 ? 'il' : urlArray[0].slice(-2);
    if(this.state === 'il'){
      this.legalURL=environment.BAE_LEGAL_IL;
    }else if(this.state === 'nm'){
      this.legalURL=environment.BAE_LEGAL_NM;
    }else if(this.state === 'mt'){
      this.legalURL=environment.BAE_LEGAL_MT;
    }else if(this.state === 'tx'){
      this.legalURL=environment.BAE_LEGAL_TX;
    }else if(this.state === 'ok'){
      this.legalURL=environment.BAE_LEGAL_OK;
    }
  }

  getCorp(code){
    if(code === 'il') return "Illinois";
    if(code === 'tx') return  "Texas";
    if(code === 'mt') return "Montana";
    if(code === 'ok') return  "Oklahoma";
    if(code === 'nm') return  "New Mexico";
  }

}
