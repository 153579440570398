<div class="body">
    <app-bae-header></app-bae-header>
    <main>
        <div class="fiber-grid resp-margin">
            <div class="fiber-grid__row">
                <div class="fiber-grid__col fiber-grid__col--xs-4 fiber-grid__col--md-5">
                      <app-bae-leftcontent></app-bae-leftcontent>
                    </div>
    
                    <div class="baep-form-grid fiber-grid__col fiber-grid__col--xs-4  fiber-grid__col--offset-md-1 fiber-grid__col--md-5">
                        <article class="fiber-box">
                            <div  >
                                <h3><b>Verify your account.</b></h3>
                                <br />
                                <div class="fiber-box fiber-s-1 fiber-br-2 fiber-m-1 fiber-p-4">
                                    <p>Enter your one-time verification code </p>
                                    <p>Didn't get a code? Click here to <a href="/employer/code-verification"> resend it.</a></p>
            
                                    <div fxLayout="column">
                                        <div>
                                            <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="{length:8,isPasswordInput:true,
                                            inputStyles:{width:'30px',height:'40px'}}"></ng-otp-input>
                                        </div>
                                        `
                                        <div fxLayout="row">
                                            <label class="fiber-checkbox">
                                                <input type="checkbox" style="width: 20px;height:20px;" [(ngModel)]="isChecked" (change)="checkValue(isChecked?'true':'false')"
                                                    [checked]="isChecked">
                                                <span class="fiber-checkbox__label">
                                                    Remember this device for 30 days.
                                                    <span class="icon-tooltip-container">
                                                        <span
                                                            class="fiber-icon-name-information fiber-icon--size-1 fiber-m-0 fiber-icon--color-gray-5"></span>
                                                        <div class="fiber-tooltip fiber-tooltip--element">
                                                            <span
                                                                class="fiber-text--size-2 fiber-text--color-white fiber-text--weight-bold"
                                                                role="heading">Remember This Device</span>
                                                            <p
                                                                class=" fiber-text--size-2 fiber-text--color-white fiber-pt-4 fiber-pb-2">
                                                                This will allow you to skip the verification steps on this device
                                                                for 30 days after each login. You should only save devices you
                                                                trust.</p>
                                                        </div>
                                                    </span>
                                                </span>
                                            </label>
                                        </div>

                                        <div fxLayout="row" fxLayoutAlign="end"> <button class="btn btn-primary col-4"
                                                style="border-radius: 50px;">
                                                Send Code </button>
                                        </div>
                                    </div>
                                </div>
            
            
                            </div>
                      
                        </article>
                    </div>
    
                </div>
    
            </div>
    </main>
    <app-bae-footer></app-bae-footer>
</div>
