import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import {  PasswordCallback } from '@forgerock/javascript-sdk';
import { DataShare } from 'src/app/services/datashare';

@Component({
  selector: 'app-bae-update-password',
  templateUrl: './bae-update-password.component.html',
  styleUrls: ['./bae-update-password.component.css']
})
export class BaeUpdatePasswordComponent implements OnInit {
  passwordLenth = 0;
  showPassword = false;
  baeUpdatePaswordForm!: FormGroup;
  isSubmitButtonDisabled1 = true;
  isSubmitButtonDisabled2 = true;
  @BlockUI() blockUI: NgBlockUI;
  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private dataShare:DataShare) { }
  mustHaveValidation = [
    { name: 'minAndMax', message: '8-40 characters', selected: false },
    {
      name: 'upperCase',
      message: 'An uppercase letter', selected: false

    },
    {
      name: 'lowerCase',
      message: 'A lowercase letter', selected: false
    },
    {
      name: 'number',
      message: 'A number', selected: false
    },
    {
      name: 'specialCharacter',
      message: 'A special character',
      terms: '~ ! @ # $ % ^ & * ( ) [ { }', selected: false
    },
  ];
  cannotHaveValidation = [
    {
      name: 'sequentialCharacters',
      message: 'More than two sequential characters',
      terms: 'Like 123 or ABC', selected: false
    },
    {
      name: 'repeatingCharacters',
      message: 'More than two repeating characters',
      terms: 'Like 111 or AAA', selected: false
    },
    {
      name: 'month',
      message: 'Abbreviations for months',
      terms: 'Like Jan, Feb, or Mar', selected: false
    },
    {
      name: 'day',
      message: 'Abbreviations for days',
      terms: 'Like Mon, Tue, or Wed', selected: false
    },
    {
      name: 'restricted',
      message: 'Restricted terms',
      terms:
        'Appl, Asdf, Basic, Cadam, Demo, Focus, Game, Net, New, Pas, Ros, Sign, Sys, Tso, Valid, Vtam, Log, Xxx, 1234',
      selected: false
    },
    {
      name: 'specialCharacter',
      message: 'A special character',
      terms: '= _ - + ', selected: false
    },
    {
      name: 'name',
      message: 'Your first or last name, or username or hcscCorpCode', selected: false
    },
    {
      name: 'password',
      message: 'Last 24 passwords cannot be used', selected: false
    }

  ];

  ngOnInit(): void {
    this.baeUpdatePaswordForm = this.formBuilder.group({

      password: ['', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(40)
      ]]
    });
  }

  passwordChange(event: any) {
    this.passwordLenth = event.length;
    //Validate minimum length

    if (event.length >= 8) {
      this.mustHaveValidation[0].selected = true;
    } else {
      this.mustHaveValidation[0].selected = false;
    }
    //Validate capital latters
    var CapitalLetters = /[A-Z]/g;
    if (event.match(CapitalLetters)) {
      this.mustHaveValidation[1].selected = true;
    } else {
      this.mustHaveValidation[1].selected = false;
    }
    //Validate lowercase latters
    var lowerCaseLetters = /[a-z]/g;
    if (event.match(lowerCaseLetters)) {
      this.mustHaveValidation[2].selected = true;

    } else {
      this.mustHaveValidation[2].selected = false;
    }

    //Validate number
    var numbers = /[0-9]/g;
    if (event.match(numbers)) {
      this.mustHaveValidation[3].selected = true;

    } else {
      this.mustHaveValidation[3].selected = false;
    }
    //Validate Special character
    var numbers = /[~!@#$%^&*()[{}]/;
    if (event.match(numbers)) {
      this.mustHaveValidation[4].selected = true;

    } else {
      this.mustHaveValidation[4].selected = false;
    }

    
    //can't have validations
    //sequentials
    let str = '~ ! @ # $ % ^ & * ( ) [ { }';

    for (const character in event) {
      if (
        String.fromCharCode(event.charCodeAt(character) + 1) ===
        event[+character + 1] &&
        !str.includes(event[character]) &&
        String.fromCharCode(event.charCodeAt(character) + 2) ===
        event[+character + 2]
      ) {
        this.cannotHaveValidation[0].selected = true;
      } else {
        this.cannotHaveValidation[0].selected = false;
      }
    }
    if (this.cannotHaveValidation[0].selected = false) {
      for (const character in event) {
        if (
          +event[+character + 1] === +event[character] + 1 &&
          +event[+character + 2] === +event[character] + 2
        ) {
          this.cannotHaveValidation[0].selected = true;
        } else {
          this.cannotHaveValidation[0].selected = false;
        }
      }
    }
    if (/(.)\1\1/.test(event)) {
      this.cannotHaveValidation[1].selected = true;
    } else {
      this.cannotHaveValidation[1].selected = false;
    }

    // Check for sequential numerical characters
    var test = (x: any) => !isNaN(x);
    var check = (x: any, y: any, i: any) => x + i === y;

    for (var i = 0; i < event.length - 2; i++) {
      if (test(event[i])) {
        if (test(event[i + 1]) && test(event[i + 2])) {
          if (check(Number(event[i]), Number(event[i + 1]), 1) &&
            check(Number(event[i]), Number(event[i + 2]), 2)) {
            this.cannotHaveValidation[0].selected = true;
          } 
        }
      } else if (!test(event[i + 1]) && !test(event[i + 2])) {
        if (check(event.charCodeAt(i), event.charCodeAt(i + 1), 1) &&
          check(event.charCodeAt(i), event.charCodeAt(i + 2), 2)) {
          this.cannotHaveValidation[0].selected = true;
        } 
      }
    }
   
    let monthsAbb = moment.monthsShort();

    if (monthsAbb.find(month => (event.toUpperCase()).match(month.toUpperCase()))) {
      this.cannotHaveValidation[2].selected = true;
    } else {
      this.cannotHaveValidation[2].selected = false;
    }
    let daysAbb = Array.apply(null, Array(7)).map(function (_, i) {
      return moment(i, 'e')
        .startOf('week')
        .isoWeekday(i + 1)
        .format('ddd');
    });

    if (daysAbb.find(day => (event.toUpperCase()).match(day.toUpperCase()))) {
      this.cannotHaveValidation[3].selected = true;
    } else {
      this.cannotHaveValidation[3].selected = false;
    }
    let words: string[] =
      ['SYS|LOG|TSO|PAS|NEW|NET|ROS|XXX|SIGN|VTAM|DEMO|ASDF|1234|BASIC|FOCUS|CADAM|VALID|GAME|APPL'];
    words = words[0].split('|');
    if (words.find((restricted: any) => (event.toUpperCase()).match(restricted.toUpperCase()))) {
      this.cannotHaveValidation[4].selected = true;
    } else {
      this.cannotHaveValidation[4].selected = false;
    } 
         //Validate Special character
   let str1 = '_  -+  =';
    for (const character in event) {
      if ( str1.includes(event[character]) )
       {
        this.cannotHaveValidation[5].selected = true;
      } else {
        this.cannotHaveValidation[5].selected = false;
      }
    }
    this.isSubmitButtonDisabled1 = !this.mustHaveValidation.every(x => x.selected) ;
    this.isSubmitButtonDisabled2 = this.cannotHaveValidation.some(x => x.selected) ;
  }
  
  onSubmit() {
    this.blockUI.start('Loading...');

    this.dataShare?.step?.callbacks?.forEach((callback, index) => {
      if (index == 0 && callback.getType() === "PasswordCallback") {
        (callback as PasswordCallback).setPassword(this.baeUpdatePaswordForm.value.password);
      }
    });
    this.router.navigate(['/employer/login']);
  }

}

