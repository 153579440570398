
    <article class="baep-hero fiber-box">
        <div class="fiber-box fiber-text--size-3 fiber-d-inline ">
            <h1 class="fiber-text--size-5 fiber-text--weight-bold ">What can I do with Blue Access for Producers?</h1>
            <h2 class="fiber-text--size-3 fiber-mt-4 fiber-mb-4 " id="List_Hero">With Blue Access for Producers (BAP), you can:</h2>                
            <ul class="fiber-list fiber-list--dark fiber-list--spacing-0 fiber-list--none fiber-mb-4 ">
                <li class="fiber-list__item">Obtain an instant preliminary quote whenever customers call</li>
                <li class="fiber-list__item">View, download, or print the information you need</li>
                <li class="fiber-list__item">View product information</li>
                <li class="fiber-list__item">View your commission statements</li>
            </ul>
    
            <h2 class="fiber-mb-4">For questions about BAP, contact the
                Blue Access&#174; Internet Help Desk: <br>
                <a class="fiber-link fiber-link--size-4" href="tel:+18887060583">888-706-0583</a>
            </h2>
                <p role="contentinfo" aria-label="Open Monday through Friday from 7 a.m. to 10 p.m. Central Time and Saturday 7 a.m. to 3:30 p.m. Central Time">Mon – Fri: 7 a.m. – 10 p.m. Central Time<br/>
            Sat: 7 a.m. – 3:30 p.m. Central Time</p>
            
            	<div *ngIf= 'showlink'>
                    <p class="fiber-ta-right">
                    <a class="fiber-link fiber-d-block fiber-ta-right fiber-link--size-1" routerLink="/" (click)="onClickFAQ()" data-qa-link="" name="BAP_FAQ">Multifactor Authentication Guide</a>
                    </p>  

				</div> 

            
        </div>
    </article>