
<div class="body">
	<app-bae-header></app-bae-header>
	 <main>
		<div class="fiber-grid resp-margin">
			<div class="fiber-grid__row">
				<div class="fiber-grid__col fiber-grid__col--xs-4 fiber-grid__col--md-5">
					  <app-bae-leftcontent></app-bae-leftcontent>
					</div>
	
					<div class="baep-form-grid fiber-grid__col fiber-grid__col--xs-4  fiber-grid__col--offset-md-1 fiber-grid__col--md-5">
						<article class="fiber-box">
							<div  >
								<h1 class="baep-verify-heading fiber-text--size-5 fiber-text--weight-bold fiber-pt-3 ">Verify Your Account.</h1>
								<h2 class="fiber-text--size-2 fiber-pt-1 fiber-pb-4">To keep your information safe, we’ll send you a one-time verification code to enter before you log in.</h2>
			
									<div class="fiber-box fiber-s-1 fiber-br-2 fiber-m-1 fiber-p-4">
										<p><b>Where should we send your code?</b></p>
										<form [formGroup]="sendCodeform" (ngSubmit)="onSubmit()">
											<div fxLayout="column">
												<div>
	
													<input type="radio" value="email" id="email" formControlName="sendCodeType">
													<label for="email" ><span style="margin-left: 20px;">Send me an email</span><br />
													<span style="margin-left: 40px;">{{email}}</span></label>
		
												</div>
												 <div>
													<input type="radio" value="phone" id="phone" formControlName="sendCodeType">
													<label for="phone" ><span style="margin-left: 20px;">Send me text
														message</span><br/>
														<span style="margin-left: 40px;">{{phone}}</span></label>
												</div>
				
												<div fxLayout="row" fxLayoutAlign="end"> <button class="btn btn-primary col-4"
														style="border-radius: 50px;">
														Send Code </button>
												</div>
											</div>
										</form>
									</div>
			
							</div>
						 
						</article>
					</div>
	
				</div>
	
			</div>

	 </main>
	 <app-bae-footer></app-bae-footer>
</div>

	
  




