<footer class="fiber-bg-blue-primary fiber-mt-7">
    <div class="fiber-grid resp-margin">
        <div class="fiber-grid__row">
            <div class="fiber-grid__col fiber-grid__col--md-12 fiber-pb-4 fiber-pb-4 fiber-text--color-white">
                <p class="fiber-mt-3 fiber-link--size-2  ">            
                    <a class=" fiber-link fiber-link--inverse fiber-link--inline" target="blank" href="{{legalURL}}">Legal and Privacy <span class="fiber-icon--name-external-link fiber-icon--size-1 f fiber-mr-1"></span></a>
                </p>
               <p class="fiber-text fiber-text-area fiber-text--size-1 fiber-mt-3 fiber-ta-center">
    Blue Cross and Blue Shield of {{corpCode}}, a Division of Health Care Service Corporation, a Mutual Legal Reserve Company,
    an Independent Licensee of the Blue Cross and Blue Shield Association
    
    </p>
    <p class="fiber-text fiber-text-area fiber-text--size-1 fiber-mt-3 fiber-ta-center">  © Copyright 2023 Health Care Service Corporation. All Rights Reserved.
    </p>

    <p class="fiber-text fiber-text-area fiber-text--size-1 fiber-mt-3 fiber-ta-center"><span class="fiber-icon--name-document-pdf-solid fiber-icon--size-1 fiber-mr-1"></span>File is in portable document format (PDF). To view this file, you may need to install a PDF reader program. Most PDF readers are a free download. One option is Adobe® Reader® which has a built-in reader. You can download other tools and learn more about accessibility at <a href="https://www.adobe.com/accessibility.html">adobe.com</a> <span class="fiber-icon--name-external-link fiber-icon--size-1 fiber-icon--color-white"></span></p>
    <p class="fiber-text fiber-text-area fiber-text--size-1 fiber-mt-3 fiber-ta-center"><span class="fiber-icon--name-external-link fiber-icon--size-1 f fiber-mr-1"></span>You are leaving this website/app (“site”). The new site may be offered by a vendor or an independent third party. The site may also contain non-Medicare related information. Some sites may require you to agree to their terms of use and privacy policy.</p>
    </div>

        </div>
    </div>

</footer>
