<div class="body">
    <app-bae-header></app-bae-header>
    <main>
        <div class="fiber-grid resp-margin">
            <div class="fiber-grid__row">
                <div class="fiber-grid__col fiber-grid__col--xs-4 fiber-grid__col--md-5">
                    <app-bae-leftcontent></app-bae-leftcontent>
                </div>
                <div class="fiber-grid__col fiber-grid__col--xs-4 fiber-grid__col--md-2"></div>
                <div class="fiber-grid__col fiber-grid__col--xs-4 fiber-grid__col--md-5">
                    <br>
                    <br>
                    &nbsp;&nbsp;&nbsp;
                    <h1 class="fiber-text--size-5 fiber-text--weight-bold fiber-mt-0">  Your BlueAccess for Employers session has timed out. Click here to 
                        <a href="" (click)="redirectToLogin()">login</a></h1>
                </div>
            </div>
        </div>
    </main>
    <app-bae-footer></app-bae-footer>
    </div>