import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { DataShare } from 'src/app/services/datashare';
import { MainService } from 'src/app/services/main.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-bap-terms-conditions',
  templateUrl: './bap-terms-conditions.component.html',
  styleUrls: ['./bap-terms-conditions.component.css']
})
export class BapTermsConditionsComponent implements OnInit {
  jsonData:any;
  posts:any;
  version: any;
 constructor(private mainService:MainService,  private router: Router,
  private dataShare:DataShare, private sanitizer: DomSanitizer) { }

 ngOnInit(): void {
  let urlArray =window.location.href.split(".com");
  let locCode= urlArray.length ==1 ?'il':urlArray [0].slice(-2);
   this.mainService.getBapTermsData(locCode).subscribe((res: any)=>{
    var doc:any = new DOMParser().parseFromString(res[0].content, "text/html");
    this.version = res[0].versionId
    this.jsonData= this.sanitizer.bypassSecurityTrustHtml(doc.firstChild.innerHTML);
   });

 }
 onReturnToLogin() {
   this.router.navigate(['/producer/login']);
 }
 onTermsAccept(){
  this.dataShare.setTouAccepted(new DatePipe('en-US').transform(new Date(), 'MM-dd-yyyy'));
   this.router.navigate(['/producer/register', {version: this.version}]);
 }
}
