<div class="body">
    <app-bae-header></app-bae-header>
    <main>
        <div class="fiber-grid resp-margin">
            <div class="fiber-grid__row">
                <div class="fiber-grid__col fiber-grid__col--xs-4 fiber-grid__col--md-5">
                    <app-bae-leftcontent></app-bae-leftcontent>
                </div>
        
                <div
                    class="baep-form-grid fiber-grid__col fiber-grid__col--xs-4  fiber-grid__col--offset-md-1 fiber-grid__col--md-5">
                    <article class="fiber-box">

                        <form class="baep-reg-form fiber-form" aria-labelledby="Baep_Reg_Form">

                            <fieldset class="fiber-fieldset fiber-bw-0 ">


                                <div class=" ">
                                    <span
                                        class="fiber-icon--size-1 fiber-icon--name-chevron-left-blue-primary  fiber-mr-1">

                                    </span>
                                    <span class="fiber-text--weight-normal  fiber-text--size-4 fiber-text--color-blue-primary"
                                        (click)="onReturnToLogin()">Back</span><br>
                                </div>


                                <h1
                                    class="baep-verify-heading fiber-text--size-5 fiber-text--weight-bold fiber-text--color-gray-5 fiber-pt-3 fiber-pb-4">
                                    Terms of Use.</h1>

                                <ng-container>
                                    <div>


                                        <br />
                                        <div class="myTemplate" style="height:400px;padding-left: 80px;">

                                            <div>
                                                <p [innerHTML]="jsonData"> </p>
                                            </div>
                                        </div>
                                    </div>

                                </ng-container>


                            </fieldset>
                            <div class="fiber-pt-3 fiber-ta-right">
                                <button name="baep-reg-continue" (click)=" onReturnToLogin()"
                                    class="fiber-button fiber-button--size-1 fiber-button--inverse  fiber-text--weight-bold fiber-mr-4 fiber-bc-blue-primary fiber-c-blue-primary fiber-bg-gray-0 fiber-mb-3 fiber-ta-right">
                                    <span class="fiber-button__text">Reject</span>
                                </button>
                                <button name="baep-reg-continue" (click)="onTermsAccept()"
                                    class="fiber-button fiber-button--size-1 fiber-button--primary fiber-text--weight-bold fiber-mb-3 fiber-ta-right">
                                    <span class="fiber-button__text">I Accept</span>
                                </button>
                            </div>

                        </form>
                    </article>
                </div>
        
            </div>
        
        </div>

    </main>
    <app-bae-footer></app-bae-footer>
</div>



