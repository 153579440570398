import { Injectable } from '@angular/core';
  
import { ToastrService } from 'ngx-toastr';
  
@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  
  constructor(private toastr: ToastrService) { }

  private timeSpan: number = 5000;
  
  showSuccess(message, title, timespan = this.timeSpan){
      this.toastr.success(message, title, {
		timeOut :  timespan,enableHtml: true
	})
  }
  
  showError(message, title, timespan = this.timeSpan){
      this.toastr.error(message, title, {
		timeOut :  timespan,enableHtml: true
	})
  }
  
  showInfo(message, title, timespan = this.timeSpan){
      this.toastr.info(message, title, {
		timeOut :  timespan,enableHtml: true
	})
  }
  
  showWarning(message, title, timespan = this.timeSpan){
      this.toastr.warning(message, title, {
		timeOut :  timespan,enableHtml: true
	})
  }
  
}