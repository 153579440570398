import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bae-verify-account',
  templateUrl: './bae-verify-account.component.html',
  styleUrls: ['./bae-verify-account.component.css']
})
export class BaeVerifyAccountComponent implements OnInit {

 
  constructor(
    public formBuilder: FormBuilder,   private router: Router,
  ) { }
  email: any;
  phone: any;
  ngOnInit() {
    this.email = "e*******@email.com";
    this.phone = "********001"
  }
  sendCodeform = this.formBuilder.group({
    sendCodeType: new FormControl('email', Validators.required)
  });

  get form() {
    return this.sendCodeform.get('sendCodeType');
  }

  onSubmit() {

   this.router.navigate(['/employer/email-verify']);
  }
}
