<div class="body">
    <app-bap-header></app-bap-header>

    <main>

        <div class="fiber-grid resp-margin">

            <div class="fiber-grid__row">

                <div class="fiber-grid__col fiber-grid__col--xs-4 fiber-grid__col--md-5">
                    <app-bap-leftcontent></app-bap-leftcontent>
                </div>

                <div
                    class="baep-form-grid fiber-grid__col fiber-grid__col--xs-4  fiber-grid__col--offset-md-1 fiber-grid__col--md-5 ">

                    <h1 class="baep-verify-heading fiber-text--size-5 fiber-text--weight-bold fiber-pt-3 fiber-pb-4">
                        Create New Password</h1>
                    <form [formGroup]="bapUpdatePaswordForm" (ngSubmit)="onSubmit()">
                        <div
                            class="fiber-box fiber-s-1 fiber-br-2 fiber-mr-1 fiber-ml-1 fiber-mb-5 fiber-mt-1 fiber-p-3">
                            <div class="form-group">
                                <label for="password"><b>Password</b></label>
                                <div class=" inner-addon right-addon">
                                    <div class="input-group mx-auto">
                                        <input type="password" formControlName="password" minlength="8" maxlength="40"
                                            (ngModelChange)="passwordChange($event)" class="form-control"
                                            [type]="showPassword ? 'text' : 'password'" />

                                        <div>
                                            <i alt="show" class="far fa-eye eye-show"
                                                (click)="showPassword =!showPassword" [class.hide]="showPassword"><span>
                                                    /40</span></i>

                                            <i alt="hide" class="far fa-eye-slash eye-hide"
                                                (click)="showPassword =!showPassword"
                                                [class.hide]="!showPassword"><span>
                                                    /40</span></i>
                                        </div>
                                        <div class="invalid-feedback">
                                            <div>Password is required</div>
                                            <div>
                                                Password must be at least 8 characters
                                            </div>
                                            <div>
                                                Password must not exceed 40 characters
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="fiber-grid__row fiber-mb-4">

                                <div class="fiber-grid__col fiber-grid__col--md-6">
                                    <p class="fiber-text--weight-bold fiber-text--size-1 fiber-mt-2 fiber-mb-2">

                                        Password Must Have</p>
                                    <div *ngFor="let must of mustHaveValidation; let i=index">
                                        <p class="fiber-text--size-1"><input type="radio" [checked]="must.selected"
                                                id="must.name{{i}}"> {{must.message}}
                                            <br /><span style="margin-left: 12px;">{{must.terms}}</span>
                                        </p>
                                    </div>

                                </div>

                                <div class="fiber-grid__col fiber-grid__col--md-6">
                                    <p class="fiber-text--weight-bold fiber-text--size-1 fiber-mt-2 fiber-mb-2">
                                        Password Can't Have
                                    </p>
                                    <div>
                                        <div *ngFor="let cant of cannotHaveValidation; let i=index">
                                            <p class="fiber-text--size-1"><input type="radio" [value]="cant.name"
                                                    [checked]="cant.selected" id="cant.name{{i}}"> {{cant.message}}
                                                <br /><span style="margin-left: 12px;">{{cant.terms}}</span>
                                            </p>
                                        </div>
                                    </div>

                                </div>
                            </div>


                            <div class="fiber-pt-3 fiber-ta-right">
                                <button class="fiber-button fiber-button--size-1 fiber-button--primary fiber-ta-right"
                                [disabled]="isSubmitButtonDisabled1 || isSubmitButtonDisabled2"
                                (click)="onSubmit()">
                                <span class="fiber-button__text">Submit</span>
                            </button>
                            </div>
                        </div>
                    </form>
                </div>

            </div>

        </div>

    </main>





    <app-bap-footer></app-bap-footer>
</div>