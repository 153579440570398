export class BaeRegister{
    constructor(
        public firstName:any,
        public lastName:any,
        public email:any,
        public corpCode:any,
        public hcscTou:any,
        public producerNPN:any,
        public telephoneNumber:any,
        public telephoneExt:any,
        public mobile:any,
        public accountType:any,
        public registrationSrc:any,
        public SSOUid:any,
        public SSOClientId:any,
        public description:any,
        public comment:any,
        public zipCode:any,
        public accountNumber:any,
        public userId:any,
        public password:any,
    ){
    }
}