<div class="body">
    <app-bap-header></app-bap-header>
    <main>
        <div *ngIf="selected==0" class="fiber-grid resp-margin">
            <div class="fiber-grid__row">

                <div
                    class="baep-hero-grid baep-reg-hero-grid fiber-grid__col fiber-grid__col--xs-4 fiber-grid__col--md-5">
                    <article class="baep-hero">

                        <ul
                            class="fiber-list fiber-list--title fiber-text--size-4 fiber-list--gray fiber-list--none fiber-list--spacing-3 fiber-text--weight-bold ">
                            <li class="fiber-list__item">
                                <a class="fiber-text--color-gray-5" active style="color:#464645">Enter Producer
                                    Information</a>
                            </li>
                            <li class="fiber-list__item">
                                <a class=" fiber-text--color-gray-3" disabled style="color:#dedede">Create Login</a>
                            </li>
                            <li class="fiber-list__item">
                                <a class="fiber-text--color-gray-3" disabled style="color:#dedede">Verify Your Email</a>

                            </li>
                        </ul>

                    </article>
                </div>

                <div
                    class="baep-form-grid fiber-grid__col fiber-grid__col--xs-4  fiber-grid__col--offset-md-1 fiber-grid__col--md-5">
                    <article class="fiber-box">

                        <form class="baep-reg-form fiber-form" aria-labelledby="Baep_Reg_Form"
                            [formGroup]="producerInfoForm" (ngSubmit)="onSubmit()">

                            <fieldset class="fiber-fieldset fiber-bw-0 ">


                                <div class=" ">
                                    <span
                                        class="fiber-icon--size-1 fiber-icon--name-chevron-left-blue-primary  fiber-mr-1">

                                    </span>
                                    <span
                                        class="fiber-text--weight-normal  fiber-text--size-4 fiber-text--color-blue-primary"
                                        (click)=" onReturnToLogin()">Back</span><br>
                                    <p class="fiber-text--weight-bold fiber-text--color-gray-5 fiber-mt-3">Step 1 of 3
                                    </p>
                                </div>


                                <h1
                                    class="baep-verify-heading fiber-text--size-5 fiber-text--weight-bold fiber-text--color-gray-5 fiber-pt-3 fiber-pb-4">
                                    Please fill out your producer information.</h1>

                                <div
                                    class="fiber-box fiber-s-1 fiber-br-2 fiber-mr-1 fiber-ml-1 fiber-mb-5 fiber-mt-1 fiber-p-3">
                                    <div class="form-group">
                                        <label for="producerNumber"><b>*Producer Number</b></label>
                                        <input type="text" formControlName="producerNumber" maxlength="9"
                                            class="form-control" />
                                        <div *ngIf="submitted && regForm.producerNumber.errors" class="invalid-feedback">
                                            <div *ngIf="regForm.producerNumber.errors.required">Producer Number is required
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="nationalProducerNumber"><b>*National Producer Number
                                                (NPN)</b></label>
                                        <input type="text" formControlName="nationalProducerNumber" maxlength="10"
                                            class="form-control" />
                                        <div *ngIf="submitted && regForm.nationalProducerNumber.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="regForm.nationalProducerNumber.errors.required">National Producer
                                                Number is required</div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="zipCode"><b>*Zip Code</b></label>
                                        <input type="text" formControlName="zipCode" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && regForm.zipCode.errors }" />
                                        <div *ngIf="submitted && regForm.zipCode.errors" class="invalid-feedback">
                                            <div *ngIf="regForm.zipCode.errors.required">Zip Code is required</div>
                                            <div *ngIf="regForm.zipCode.errors.minlength">
                                                Zip Code must be 5 Digit Number
                                            </div>
                                            <div *ngIf="regForm.zipCode.errors.maxlength">
                                                Zip Code must be 5 Digit Number
                                            </div>
                                            <div *ngIf="regForm.zipCode.errors.pattern">Enter only number.</div>

                                        </div>
                                        <span class="fiber-text fiber-text--size-1 fiber-text--color-text-dark  "
                                            id="ProducerZip-message" aria-labelledby="ProducerZip_Label">
                                            Use the zip code found on your Producer Agreement
                                        </span>
                                    </div>


                                </div>

                            </fieldset>
                            <div class="fiber-pt-3 fiber-ta-right">
                                <button name="baep-reg-continue" (click)="handleProducer()"
                                    class="fiber-button fiber-button--size-1 fiber-button--primary fiber-text--weight-bold fiber-mb-3 fiber-ta-right">
                                    <span class="fiber-button__text">Continue</span>
                                </button>
                            </div>

                        </form>

                    </article>
                </div>

            </div>
        </div>

        <div *ngIf="selected==1" class="fiber-grid resp-margin">
            <div class="fiber-grid__row">

                <div
                    class="baep-hero-grid baep-reg-hero-grid fiber-grid__col fiber-grid__col--xs-4 fiber-grid__col--md-5">
                    <article class="baep-hero">

                        <ul
                            class="fiber-list fiber-list--title fiber-text--size-4 fiber-list--gray fiber-list--none fiber-list--spacing-3 fiber-text--weight-bold ">
                            <li class="fiber-list__item">
                                <a class="fiber-text--color-gray-3" disabled style="color:#dedede">Enter Producer
                                    Information</a>
                            </li>
                            <li class="fiber-list__item">
                                <a class=" fiber-text--color-gray-5" active style="color:#464645">Create Login</a>
                            </li>
                            <li class="fiber-list__item">
                                <a class="fiber-text--color-gray-3" disabled style="color:#dedede">Verify Your Email</a>

                            </li>
                        </ul>

                    </article>
                </div>

                <div
                    class="baep-form-grid fiber-grid__col fiber-grid__col--xs-4  fiber-grid__col--offset-md-1 fiber-grid__col--md-5">
                    <article class="fiber-box">

                        <form class="baep-createlogin-form fiber-form" aria-labelledby="Baep_Create_Login_Form"
                            [formGroup]="producerLoginForm" (ngSubmit)="onLoginSubmit()">

                            <fieldset class="fiber-fieldset">

                                <p
                                    class="fiber-d-flex fiber-text--size-2 fiber-text--weight-bold fiber-text--color-gray-4">
                                <div class="">
                                    <span
                                        class="fiber-icon--size-1 fiber-icon--name-chevron-left-blue-primary  fiber-mr-1">

                                    </span>
                                    <span
                                        class="fiber-text--weight-normal   fiber-text--size-4 fiber-text--color-blue-primary "
                                        (click)="backtoStep0()">Back</span><br>
                                    <p class="fiber-text--weight-bold fiber-text--color-gray-5 fiber-mt-3">Step 2 of 3
                                    </p>
                                </div>



                                <h1
                                    class="baep-verify-heading fiber-text--size-5 fiber-text--weight-bold fiber-pt-3 fiber-pb-4">
                                    Create Your Secure Login.</h1>

                                <div
                                    class="fiber-box fiber-s-1 fiber-br-2 fiber-mr-1 fiber-ml-1 fiber-mb-5 fiber-mt-1 fiber-p-3">
                                    <div class="form-group">
                                        <label for="userId"><b>User ID</b></label>
                                        <div class="fiber-grid__row">

                                            <div
                                                class="fiber-grid__col fiber-grid__col--xs-4 fiber-grid__col--sm-2 fiber-grid__col--md-12">

                                                <div class="input-group mx-auto">
                                                    <input id="baep-reg-userid"
                                                        class="baep-reg-userid-prefix fiber-input__input fiber-mt-0"
                                                        name="email" type="text" [value]="producerInfoForm.value.producerNumber"
                                                        disabled="true">
                                                </div>
                                            </div>

                                        </div>
                                    </div>


                                    <div class="form-group">
                                        <label for="password"><b>Password</b></label>
                                        <div class=" inner-addon right-addon">
                                            <div class="input-group mx-auto">
                                                <input type="password" minlength="8" maxlength="40"
                                                    formControlName="password" (ngModelChange)="passwordChange($event)"
                                                    class="form-control"
                                                    [ngClass]="{ 'is-invalid': submitted && regForm2.password.errors }"
                                                    [type]="showPassword ? 'text' : 'password'" />

                                                <div>
                                                    <i alt="show" class="far fa-eye eye-show"
                                                        (click)="showPassword =!showPassword"
                                                        [class.hide]="showPassword"><span>
                                                            {{passwordLenth}}/40</span></i>

                                                    <i alt="hide" class="far fa-eye-slash eye-hide"
                                                        (click)="showPassword =!showPassword"
                                                        [class.hide]="!showPassword"><span>
                                                            {{passwordLenth}}/40</span></i>
                                                </div>
                                                <div *ngIf="submitted && regForm2.password.errors " class="invalid-feedback">
                                                    <div *ngIf="regForm2.password.errors.required">Password is required</div>
                                                    <div *ngIf="regForm2.password.errors.minlength">
                                                        Password must be at least 8 characters
                                                    </div>
                                                    <div *ngIf="regForm2.password.errors.maxlength">
                                                        Password must not exceed 40 characters
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="fiber-grid__row fiber-mb-4">

                                        <div class="fiber-grid__col fiber-grid__col--md-6">
                                            <p class="fiber-text--weight-bold fiber-text--size-1 fiber-mt-2 fiber-mb-2">

                                                Password Must Have</p>
                                            <div *ngFor="let must of mustHaveValidation; let i=index">
                                                <p class="fiber-text--size-1"><input type="radio"
                                                        [checked]="must.selected" id="must.name{{i}}"> {{must.message}}
                                                    <br /><span style="margin-left: 12px;">{{must.terms}}</span>
                                                </p>
                                            </div>

                                        </div>

                                        <div class="fiber-grid__col fiber-grid__col--md-6">
                                            <p class="fiber-text--weight-bold fiber-text--size-1 fiber-mt-2 fiber-mb-2">
                                                Password Cannot Have
                                            </p>
                                            <div *ngFor="let cant of cannotHaveValidation; let i=index">
                                                <p class="fiber-text--size-1"><input type="radio" [value]="cant.name"
                                                        [checked]="cant.selected" id="cant.name{{i}}"> {{cant.message}}
                                                    <br /><span style="margin-left: 12px;">{{cant.terms}}</span>
                                                </p>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </fieldset>
                            <div class="fiber-pt-3 fiber-ta-right">
                                <button class="fiber-button fiber-button--size-1 fiber-button--primary fiber-ta-right"
                                [disabled]="isSubmitButtonDisabled1 || isSubmitButtonDisabled2">
                                    <span class="fiber-button__text">Create Login</span>
                                </button>
                            </div>
                        </form>
                    </article>
                </div>

            </div>
        </div>

        <div *ngIf="selected==2" class="fiber-grid resp-margin">
            <div class="fiber-grid__row">

                <div
                    class="baep-hero-grid baep-reg-hero-grid fiber-grid__col fiber-grid__col--xs-4 fiber-grid__col--md-5">
                    <article class="baep-hero">

                        <ul
                            class="fiber-list fiber-list--title fiber-text--size-4 fiber-list--gray fiber-list--none fiber-list--spacing-3 fiber-text--weight-bold ">
                            <li class="fiber-list__item">
                                <a class="fiber-text--color-gray-3" disabled style="color:#dedede">Enter Producer
                                    Information</a>
                            </li>
                            <li class="fiber-list__item">
                                <a class=" fiber-text--color-gray-3" disabled style="color:#dedede">Create Login</a>
                            </li>
                            <li class="fiber-list__item">
                                <a class="fiber-text--color-gray-5" active style="color:#464645">Verify Your Email</a>

                            </li>
                        </ul>

                    </article>
                </div>

                <div
                    class="baep-form-grid fiber-grid__col fiber-grid__col--xs-4  fiber-grid__col--offset-md-1 fiber-grid__col--md-5">
                    <article class="fiber-box">

                        <form class="baep-verify-email-form fiber-form" aria-labelledby="Baep_Verify_Email_Form">

                            <fieldset class="fiber-fieldset">

                                <p
                                    class="fiber-d-flex fiber-text--size-2 fiber-text--weight-bold fiber-text--color-gray-4">
                                <div class="">
                                    <span
                                        class="fiber-icon--size-1 fiber-icon--name-chevron-left-blue-primary  fiber-mr-1">

                                    </span>

                                    <p class="fiber-text--weight-bold fiber-text--color-gray-5 fiber-mt-3">Step 3 of 3
                                    </p>
                                </div>



                                <h1
                                    class="baep-verify-heading fiber-text--size-5 fiber-text--weight-bold fiber-pt-3 fiber-pb-3">
                                    Verify Your email.</h1>

                                <div class="fiber-box">
                                    <h2 class="fiber-text--size-2 fiber-pt-5 fiber-pb-5">This helps us keep your account
                                        secure. Click the link in the email we sent to the email associated with your
                                        account. You can log in once your email has been verified.
                                    </h2>
                                    <div
                                        class="fiber-flex fiber-flex--direction-responsive fiber-flex--wrap-nowrap fiber-flex--justify-content-space-between fiber-flex--align-items-stretch fiber-flex--gap-support fiber-flex--gap-responsive fiber-flex--basis-0 fiber-flex--divider fiber-flex--block fiber-d-flex">
                                        <button (click)="onReturnToLogin()"
                                            class="fiber-button fiber-button--size-1 fiber-button--primary fiber-button--block"
                                            data-qa-button="Return to Log In">
                                            <span class="fiber-button__text" data-qa-button-label="Return to Log In">
                                                Return to Log In
                                            </span>
                                        </button>
                                    </div>
                                </div>

                            </fieldset>


                        </form>
                    </article>
                </div>

            </div>
        </div>
    </main>

    <app-bap-footer></app-bap-footer>
</div>
